import { notify } from '../utilities';
import { UserActions } from '../../store/actions';

export default () => next => action => {
  return typeof action === 'function'
    ? next(async (dispatch, getState) => {
        try {
          return await action(dispatch, getState);
        } catch (error) {
          dispatch(UserActions.hideHUD());
          let response = error.response;
          console.log('error', response, error);
          if (response && (response.data || response.statusText)) {
            if (response.data.errors && response.data.errors[0] === 'Athlete not authorized') {
              dispatch(UserActions.logoutPressed());
              localStorage.clear();
            }

            if (response.config.url) {
              console.log(response.data ? response.data.errors : response.statusText);
              notify(
                parseErrors(
                  response.data && response.data.errors ? response.data.errors : response.statusText
                ).join(', ')
              );
            }
          } else if (error && error.message) {
            if (!error.message === 'Network Error') {
              notify(error.message);
            }
          } else {
            throw error;
          }
        }
      })
    : next(action);
};

const parseErrors = list => {
  if (typeof list === 'string') {
    list = [list];
  } else {
    if (list && list.length > 0 && typeof list[0] === 'string') {
      list = list;
    } else {
      list = Object.entries(list).map(([key, value]) => {
        return value;
        // value.map(err => {
        //   return key === 'base'
        //     ? value
        //     : `${key
        //         .replace(/\./g, ' ')
        //         .replace(/_/g, ' ')
        //         .replace(/(?: |\b)(\w)/g, key => {
        //           return key.toUpperCase();
        //         })} ${err}`;
        // })
      });
    }
  }
  return list;
};
