import React, { Fragment, useState } from 'react';
import SelectDatepicker from 'react-select-datepicker';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { passingYear } from '../../../constants';
import { InputLabel, OnboardingAction, PrivacyText } from '../../Shared';

function AccountInfo({
  email,
  firstName,
  lastName,
  password,
  passwordConfirmation,
  handleOnChange,
  gender,
  history,
  phoneNumber,
  dob,
  invalidPhone,
  classPassed,
}) {
  const [privacyChecked, setPrivacyChecked] = useState(false);

  return (
    <Fragment>
      <div className="container-wrap">
        <div className="container-half md:pr-3">
          <div className="text-left">
            <InputLabel label="First Name" color="black" />

            <Form.Control
              type="text"
              className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs"
              onChange={e => handleOnChange('firstName', e.target.value)}
              value={firstName}
              required
              autoFocus
            />
          </div>
        </div>

        <div className="container-half">
          <div className="text-left ">
            <InputLabel label="Last Name" color="black" />

            <Form.Control
              type="text"
              className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs"
              onChange={e => handleOnChange('lastName', e.target.value)}
              value={lastName}
              required
            />
          </div>
        </div>
      </div>

      <div className="mt-3">
        <div className="text-left w-full">
          <InputLabel label="Email" color="black" />

          <Form.Control
            type="email"
            className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs"
            onChange={e => handleOnChange('email', e.target.value)}
            value={email}
            required
          />
        </div>
      </div>

      <div className="mt-3">
        <div className="container-wrap items-center">
          <div className="container-half md:pr-3">
            <div className="text-left w-full">
              <InputLabel label="Gender" color="black" />

              <Select
                className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs text-black"
                placeholder="Select gender"
                value={gender}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
                onChange={selectedOption => {
                  handleOnChange('gender', selectedOption);
                  handleOnChange('position', null);
                }}
                options={[
                  { value: 'female', label: 'Female' },
                  { value: 'male', label: 'Male' },
                ]}
              />
            </div>
          </div>

          <div className="container-half">
            <div className="w-full text-left">
              <InputLabel label="Phone Number" color="black" />

              <InputMask
                placeholder="(555) 555-5555"
                style={{ outline: 0, fontFamily: 'SF-light' }}
                className="font-sf-regular text-xs rounded input-mask form-control"
                mask="+1 (999) 999-9999"
                maskChar=" "
                value={phoneNumber}
                onChange={e => handleOnChange('phoneNumber', e.target.value)}
              />
            </div>
          </div>
        </div>

        <p className={`${invalidPhone ? 'text-center' : 'd-none'}`}>
          <small className="font-sf-regular text-xs text-red-800">Invalid phone number</small>
        </p>
      </div>

      <div className="mt-3">
        <div className="text-left w-full">
          <InputLabel label="Birthdate" color="black" />

          <SelectDatepicker
            format="month/day/year"
            value={dob}
            onDateChange={date => {
              handleOnChange('dob', date);
            }}
            value={dob}
            minDate={new Date(1900, 0, 1)}
            maxDate={new Date(moment().subtract(14, 'years').calendar())}
            showLabels={false}
          />
        </div>

        <div className="text-left mt-3">
          <InputLabel label="College Grad Year" color="black" />

          <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            className="primary-text-regular"
            placeholder="Select class"
            value={classPassed ? classPassed : passingYear[50]}
            isSearchable={false}
            onChange={selectedOption => handleOnChange('passingYear', selectedOption)}
            options={passingYear}
            maxMenuHeight={150}
          />
        </div>
      </div>

      <div className="mt-3">
        <div className="text-left w-full">
          <InputLabel label="Password" color="black" />

          <Form.Control
            minLength={7}
            type="password"
            className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs"
            onChange={e => handleOnChange('password', e.target.value)}
            value={password}
            required
          />
        </div>
      </div>

      <div className="mt-3">
        <div className="text-left w-full">
          <InputLabel label="Confirm password" color="black" />

          <Form.Control
            minLength={7}
            type="password"
            className="font-sf-regular text-xs"
            onChange={e => handleOnChange('passwordConfirmation', e.target.value)}
            value={passwordConfirmation}
            required
          />
        </div>
      </div>

      <label class="inline-flex items-center">
        <input
          type="checkbox"
          className="form-checkbox text-primary-color h-6 w-6"
          checked={privacyChecked}
          onClick={() => setPrivacyChecked(!privacyChecked)}
        />
        <PrivacyText className="text-left mx-3" />
      </label>

      <OnboardingAction
        disableAction={!privacyChecked}
        navigateLogin={() => {
          history.push('/athlete/login');
        }}
      />
    </Fragment>
  );
}

export default AccountInfo;
