export const trainingStep =
  'Trayvo is the easiest way to share your training schedule with players. To get started, enter the details of your first training option below.';
export const availabilityStep = trainingName =>
  `Now that you have your first training option ready, your next step is to set when you are available for ${trainingName} . You can add additional availabilities inside.`;
export const reviewStepBottom =
  'We are excited to help you manage your training schedule. Once inside, you will be able to add more training options/availabilities, make any edits, and easily manage your bookings.';
export const bookingPageLink =
  'Here is your trainer page that makes it easy for players to see when you are available. Just share your link with them and they can quickly book when they want to train and get better.';
export const trainingsTooltip =
  'Trainings listed here are added by you(or the default ones) via the new training option.';
export const availTooltip =
  'Availabilities listed here are the future availabilities you offer for the players.';
export const calendarTooltip = 'Calendar displays all your availabilities and bookings.';
export const bankDetailsStep =
  'Last step, please enter your details for the bank account you would like us to send your money to after you complete your trainings.';
export const notAllowedText =
  'Personal images are not allowed to be used for current college athletes due to NCAA rules. We will display your school color instead.';
export const availabilityPopup = 'Let players know when you are available to train.';
export const inviteCodeText =
  'Hook your trainer friends up with trayvo using your invite code below.';
export const currentAthleteAbout =
  'Reminder: Check with your compliance department on what information is allowed to be displayed under your NIL rules.';

export const tourGuide = [
  {
    selector: '.new-training-step',
    content:
      'You can create your new training option other than the default options. Hit new training and choose your desired options.',
  },
  {
    selector: '.update-training-step',
    content:
      'You can update your training option for price, duration, privacy and for other choices accordingly by pressing any of the training option.',
  },
  {
    selector: '.delete-training-step',
    content:
      'To delete any existing training option press this settings button. This way your training option will not be appear to the customers.',
  },
  {
    selector: '.create-availability-step',
    content:
      'To create single day or multiple days availability and to link the training options accordingly. Make sure the availability is for the future date & time.',
  },
  {
    selector: '.share-profile-step',
    content:
      'In order to have your first booking. Please share this URL with others so they can find the suitable time to book your training option.',
  },
  {
    selector: '.calendar-step',
    content:
      'Your availabilities will appear here in each cell of the calendar. Press any date and you will get the details of all the booked and unbooked slots. Similarly against each slot you can view the respective bookings. ',
  },
];

export const addLocationGuide = [
  {
    selector: '.add-location-btn',
    content:
      'Please add a training location to enable this training. A training option can have multiple training locations.',
  },
];

export const addConnectionDetailsGuide = [
  {
    selector: '.connection-details-input',
    content:
      'Please enter connection details to enable this training. A connection details can be Zoom, Google Meet etc link',
    action: node => {
      node.focus();
    },
  },
];

export const reviewStepTop =
  'Nice work, you are ready to go with sharing your first training option. Check out your details below on how to start using trayvo.';

export const brand = 'trayvo';

export const releasePayment = 'If training was completed, click Confirm to receive payment';

export const pendingPayments =
  'You have one or more bookings to confirm in order to release your payment. Please go to the bookings section and confirm the bookings.';

export const oneTimeAvailability = "Choose if only available on a specific date, i.e. 10am-12pm on Tue, Dec 4"
export const multipleTimeAvailability = 'Choose if same availability on multiple dates, i.e. Every Sat and Sun, 10am-12pm, Nov 24-Dec 4'
