import { combineReducers } from 'redux';
import { Map } from 'immutable';

const serviceDefault = {
  providedServices: [],
  providedServicesPagy: null,
  services: [],
  singleService: {},
};

const servicesReducer = (state = Map(serviceDefault), action) => {
  switch (action.type) {
    case 'SET_PROVIDED_SERVICES':
      if (action.payload.more)
        return state.update('providedServices', providedServices =>
          providedServices.concat(action.payload.data)
        );
      else return state.set('providedServices', action.payload.data);

    case 'SET_PROVIDED_SERVICES_PAGY':
      return state.set('providedServicesPagy', action.payload.data);

    case 'SET_SERVICES':
      return state.set('services', action.payload.data);

    case 'SET_SINGLE_SERVICE':
      return state.set('singleService', action.payload.data);

    case 'UPDATE_PROVIDED_SERVICE': {
      return state.update('providedServices', providedServices =>
        providedServices.map(item => {
          if (item.attributes.id === action.payload.id) {
            return { item, ...action.payload.data };
          } else {
            return item;
          }
        })
      );
    }

    default:
      return state;
  }
};

export default combineReducers({
  service: servicesReducer,
});
