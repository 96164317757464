import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Question } from '../../assets/img';

function TooltipIcon({ tooltipText, id }) {
  return (
    <div className="flex">
      <img src={Question} className="ml-2 h-4 w-4" data-tip={tooltipText} data-for={id} />
      <ReactTooltip
        id={id}
        type="success"
        effect="solid"
        multiline={true}
        border
        textColor="#fff"
        place="right"
        backgroundColor="#042E60"
      />
    </div>
  );
}
export default TooltipIcon;
