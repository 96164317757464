import React, { Component } from 'react';
import { TrayvoBlue } from '../../assets/img';
import { Form } from 'react-bootstrap';
import { CFormGroup } from '@coreui/react';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';
import { DurationPicker } from '../Athlete/DurationPicker';
import Select from 'react-select';
import { SelectionButton, InputLabel } from './index';
import { availableSlots, privacySettings, trainingOption } from '../../constants';
import ServiceLocation from '../Athlete/Services/ServiceLocation';
import { notify, removeSpecialCharacters, checkMinimumFee } from '../../utils/utilities';
import ImageEdit from './ImageEdit';

const initialStates = {
  editVisible: false,
  serviceObj: null,
  duration: 'Min',
  minDuration: null,
  cusDuration: null,
  link: '',
  price: '',
  desc: '',
  trainingImage: '',
  connectionDetails: '',
  selectedTraining: null,
  trainingImageFile: null,
  privacySetting: privacySettings[0],
  locations: [{ id: 1, location: '', address: '' }],
  eventName: '',
  maxSeats: null,
  trainingType: trainingOption.INDIVIDUAL,
};

class CreateTraining extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialStates,
    };
  }

  componentDidMount() {
    this.setState({ locations: [{ id: 1, location: '', address: '' }] });
    const { onboarding } = this.props;
    if (onboarding) {
      this.getOnBoardingData();
    }
  }

  async getOnBoardingData() {
    const { getProvidedServices } = this.props;
    await getProvidedServices();
  }
  addLocation = () => {
    let { locations } = this.state;
    this.setState({
      locations: [...locations, { id: locations.length + 1, location: '', address: '' }],
    });
  };

  removeLocation = id => {
    let { locations } = this.state;
    let removeLocations = locations.filter(item => item.id !== id);

    this.setState({
      locations: removeLocations,
      [`location${id}Characters`]: 0
    });
  };

  onUpdateLocations = (id, tag, value) => {
    let { locations } = this.state;
    let updatedLocations = locations.map(item => {
      if (item.id === id) item[tag] = value;
      return item;
    });
    this.setState({ locations: updatedLocations });
  };

  async submit(e) {
    e.preventDefault();
    const {
      onboarding,
      currentUser,
      changeButtonLoading,
      getTrainingSignedUrl,
      uploadImage,
      updateProvidedService,
      getProvidedServices,
      changeContent,
      createService,
      setDefaultTrainingType,
      changeTrainingNameOB,
    } = this.props;

    let {
      trainingImage,
      trainingImageFile,
      link,
      cusDuration,
      minDuration,
      locations,
      connectionDetails,
      eventName,
      privacySetting,
      maxSeats,
      trainingType,
      price,
      desc,
    } = this.state;

    if (!cusDuration && !minDuration) {
      notify('Select a duration.');
      return;
    }

    if (privacySetting === null) {
      notify('Select privacy setting.');
      return;
    }

    if (checkMinimumFee(price)) return;

    changeButtonLoading(true);

    let payload = {
      provided_service: {
        discription: desc,
        status: 1,
        slug_title: `${currentUser && currentUser.attributes.slug}/${removeSpecialCharacters(
          link
        )}`,
        price: price,
        name: eventName,
        duration: parseInt(cusDuration ? cusDuration : minDuration),
        training_type:
          trainingType === trainingOption.VIRTUAL
            ? 1
            : trainingType === trainingOption.INDIVIDUAL
              ? 2
              : 3,
        privacy_setting: privacySetting.value,
        provided_service_locations_attributes: locations.map(item => ({
          location: item.location,
          address: item.address,
        })),
      },
    };

    if (trainingType === trainingOption.VIRTUAL)
      payload.provided_service.connection_detail = connectionDetails;

    if (trainingType === trainingOption.GROUP) {
      if (maxSeats === null) {
        notify('Select available spots.');
        changeButtonLoading(false);
        return;
      }
      payload.provided_service.max_seats = maxSeats.value;
    }

    if (trainingImage && trainingImageFile) {
      const image = await getTrainingSignedUrl();
      await uploadImage(trainingImageFile, image.signed_url);
      payload.provided_service.image_url = image.url;
    }

    setDefaultTrainingType(trainingType);
    if (onboarding) {
      let providedService = this.findProvidedService(trainingType);
      if (trainingType !== 'Virtual') {
        let locations = providedService.attributes.provided_service_locations.data.map(item => ({
          id: item.id,
          _destroy: true,
        }));
        locations = locations.concat(
          this.state.locations.map(item => ({
            location: item.location,
            address: item.address,
          }))
        );
        payload.provided_service.provided_service_locations_attributes = locations;
      }

      await updateProvidedService(providedService.id, payload);
      await getProvidedServices();
      changeButtonLoading(false);
      changeContent();
      changeTrainingNameOB(eventName);
    } else {
      await createService(payload).then(async response => {
        if (response) {
          await getProvidedServices(1, 100);
          this.props.setSelectedTraining({
            trainingType: response.data.attributes.training_type,
            trainingId: response.data.attributes.id,
          });
          this.closePopup(true);
        }
      });
      changeButtonLoading(false);
    }
  }

  findProvidedService = trainingType => {
    let providedService = this.props.providedServices.filter(
      item => item.attributes.training_type === trainingType.toLowerCase()
    );
    return (providedService && providedService[0]) || null;
  };

  onChange = (tag, value) => {
    this.setState({ [tag]: value });
  };

  onCharacterInsert = (name, value) => {
    this.setState({ [`${name}Characters`]: value.length })
  }

  closePopup = (showAvailabilityPopup = false) => {
    let { closePopup } = this.props;
    this.setState({ initialStates }, () => {
      closePopup(showAvailabilityPopup && { showAvailabilityPopup: true });
    });
  };

  render() {
    const { buttonLoading, currentUser, onboarding } = this.props;

    let {
      minDuration,
      cusDuration,
      price,
      link,
      trainingImage,
      desc,
      locations,
      eventName,
      privacySetting,
      connectionDetails,
      maxSeats,
      trainingType,
    } = this.state;

    return (
      <div className="bg-white rounded-lg w-full">
        <div className={`flex flex-col px-4 relative ${onboarding ? 'py-1' : 'py-4'}`}>
          {!onboarding && (
            <div className="self-center">
              <small className="text-sm text-primary-color font-sf-medium">
                Create Training Option
              </small>
            </div>
          )}

          {!onboarding && (
            <button
              type="button"
              onClick={() => this.closePopup()}
              className="absolute top-0 right-0 p-4 rounded-lg focus:outline-none self-end">
              <small className="hover:text-blue-500 text-primary-color font-medium text-lg">
                X
              </small>
            </button>
          )}
        </div>

        <div class="flex py-1 flex-col bg-extra-gray rounded-md">
          <form
            className="w-full"
            onSubmit={e => {
              this.submit(e);
            }}>
            <div className="px-10">
              <div className="flex items-center justify-center my-2 flex-col ">
                <img
                  className="rounded-full object-cover h-20 w-20 bg-white shadow-md"
                  src={trainingImage ? trainingImage : TrayvoBlue}
                />

                <div class="flex bg-grey-lighter">
                  <ImageEdit
                    afterEditDone={(image, imageFile) => {
                      this.setState({ trainingImage: image, trainingImageFile: imageFile });
                    }}
                  />
                </div>
              </div>

              <InputLabel label="Type" />
              <div className="flex justify-between w-full">
                <div className="w-1/3">
                  <SelectionButton
                    text={trainingOption.INDIVIDUAL}
                    checked={trainingType === trainingOption.INDIVIDUAL}
                    value={trainingOption.INDIVIDUAL}
                    onClick={() => {
                      this.setState({ trainingType: trainingOption.INDIVIDUAL });
                    }}
                  />
                </div>

                <div className="w-1/3 px-2">
                  <SelectionButton
                    text={trainingOption.GROUP}
                    checked={trainingType === trainingOption.GROUP}
                    value={trainingOption.GROUP}
                    onClick={() => {
                      this.setState({ trainingType: trainingOption.GROUP });
                    }}
                  />
                </div>

                <div className="w-1/3">
                  <SelectionButton
                    text={trainingOption.VIRTUAL}
                    checked={trainingType === trainingOption.VIRTUAL}
                    value={trainingOption.VIRTUAL}
                    onClick={() => {
                      this.setState({ trainingType: trainingOption.VIRTUAL });
                    }}
                  />
                </div>
              </div>

              <DurationPicker
                minDuration={minDuration}
                cusDuration={cusDuration}
                setCusDuration={cusDuration => this.setState({ cusDuration })}
                setMinDuration={minDuration => this.setState({ minDuration })}
              />

              <div className="flex justify-between">
                <div className="w-1/2 pr-3">
                  <InputLabel label={`Training Name (${eventName.length || 0}/35 characters)`} color={eventName.length === 35 && 'red'} />
                  <Form.Control
                    maxLength="35"
                    minLength="5"
                    type="text"
                    id="custom-group"
                    placeholder="i.e. Offensive Training"
                    className="font-sf-regular bg-transparent text-black text-xs mr-2"
                    onChange={e => {
                      this.onChange('eventName', e.target.value);
                      this.onChange(
                        'link',
                        e.target.value.toLowerCase().replace(/\s/g, '').replace(/-/g, '')
                      );
                    }}
                    value={eventName}
                    required
                  />
                </div>

                <div className="w-1/2">
                  <InputLabel label="Cost" />
                  <CFormGroup id="custom-group">
                    <div className="text-left">
                      <NumberFormat
                        required
                        value={price}
                        thousandSeparator={true}
                        placeholder="$ 0.00"
                        allowNegative={false}
                        className="font-sf-regular bg-transparent text-black text-xs rounded form-control"
                        prefix={'$ '}
                        onValueChange={({ value }) => {
                          this.onChange('price', value);
                        }}
                      />
                    </div>
                  </CFormGroup>
                </div>
              </div>

              <Form.Group>
                {trainingType !== trainingOption.VIRTUAL && (
                  <div className={`flex flex-col`} key={locations.length}>
                    {locations.map((item, index) => (
                      <ServiceLocation
                        hideRemove={index === 0}
                        id={item.id}
                        locationValue={item.location}
                        addressValue={item.address}
                        totalCharacters={item.location.length ? item.location.length : 0}
                        onUpdateLocations={(id, tag, value) => {
                          this.onUpdateLocations(id, tag, value)
                        }}
                        removeLocation={id => {
                          this.removeLocation(id)

                        }}
                      />
                    ))}

                    <div className="my-1 cursor-pointer" onClick={() => this.addLocation()}>
                      <span className="text-blue-500">+Add Another Location</span>
                    </div>
                  </div>
                )}

                {trainingType === trainingOption.GROUP && (
                  <div className="flex flex-col">
                    <InputLabel label="Available Spots" />
                    <div className="text-center w-full">
                      <Select
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs text-black "
                        placeholder="Available spots"
                        isSearchable={false}
                        required
                        value={maxSeats}
                        onChange={option => this.onChange('maxSeats', option)}
                        options={availableSlots}
                      />
                    </div>
                  </div>
                )}

                {trainingType === trainingOption.VIRTUAL && (
                  <div>
                    <InputLabel label="Connection details" />
                    <Form.Control
                      type="text"
                      placeholder="Zoom link, google meet link"
                      className="font-sf-regular text-black text-xs"
                      onChange={e => {
                        this.onChange('connectionDetails', e.target.value);
                      }}
                      value={connectionDetails}
                      required
                    />
                  </div>
                )}
              </Form.Group>

              <CFormGroup>
                <InputLabel label={`Description (${desc.length || 0}/160 characters)`} color={desc.length === 160 && 'red'} />
                <textarea
                  className="text-input focus:border-primary-color"
                  value={desc}
                  placeholder="Description"
                  rows={3}
                  maxLength={160}
                  id="ccnumber"
                  required
                  onChange={e => {
                    this.onChange('desc', e.target.value)
                  }}
                />
              </CFormGroup>

              <div className="text-left w-full">
                <InputLabel label="Privacy" />
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className="primary-text-regular normal-case"
                  placeholder="Privacy"
                  isSearchable={false}
                  value={privacySetting}
                  onChange={option => this.setState({ privacySetting: option })}
                  options={privacySettings}
                />
              </div>

              <CFormGroup className="mt-2">
                <InputLabel label="Event link" />
                <div className="flex md:items-center flex-col md:flex-row">
                  <span className="font-sf-regular text-sm mr-2 text-primary-color">{`trayvo.com/${currentUser && currentUser.attributes && currentUser.attributes.slug
                    }/`}</span>

                  <Form.Control
                    type="text"
                    className=" font-sf-regular bg-transparent text-black text-xs"
                    onChange={e => {
                      this.setState({ link: e.target.value.toLowerCase() });
                    }}
                    value={removeSpecialCharacters(link)}
                    required
                  />
                </div>
              </CFormGroup>

              <div className="flex flex-row-reverse items-center">
                <button type="submit" className="primary-dark-button my-3 px-3">
                  Add Training Option
                  <Loader
                    visible={buttonLoading}
                    type="ThreeDots"
                    color="white"
                    className="ml-2 self-center"
                    height={10}
                    width={25}
                  />
                </button>

                {onboarding && (
                  <button
                    type="button"
                    onClick={this.props.changeContent}
                    className="self-end px-3 focus:outline-none underline rounded py-2 text-primary-color my-3 hover:text-blue-500 text-xs inline-flex justify-center">
                    Skip
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CreateTraining;
