import React, { useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { convertDate, formatDate, formatTime } from '../../utils/utilities';
import { CalendarActions } from '../../store/actions';
import moment from 'moment';

let connectProps = {
  ...CalendarActions,
};

let connectState = state => ({
  trainingAvailabilities: state.Calendar.calendar.get('trainingAvailabilities'),
});

let enhancer = connect(connectState, connectProps);

function AssociatedAvailabilities({ close, training, ...props }) {
  useEffect(() => {
    props.getTrainingAvailabilities(training.id);
  }, []);

  let { trainingAvailabilities } = props;

  const slotsLength = () => {
    return trainingAvailabilities && trainingAvailabilities.length;
  };

  const getSingleAvailability = id => {
    close();
    props.getAvailability(id).then(response => {
      let { data } = response;
      if (data.id) props.openUpdateDateSelection(data.id, data.attributes);
    });
  };

  return (
    <SweetAlert showConfirm={false}>
      <div>
        <h5 className="text-primary-color font-sf-medium capitalize">
          {training.name} Availability
        </h5>
        <small className="primary-text-regular text-t-gray normal-case">
          These are the availabilities you currently have connected to {training.name}
        </small>

        <div
          class={`grid grid-flow-row grid-rows-3 gap-2 mt-3 ${
            slotsLength() === 1
              ? 'grid-cols-1'
              : slotsLength() === 2
              ? 'grid-cols-2'
              : 'grid-cols-3'
          }`}>
          {trainingAvailabilities.map(({ attributes }) => {
            return (
              <div
                onClick={() => getSingleAvailability(attributes.id)}
                className="shadow-md rounded-lg p-2 flex flex-col items-center border border-gray-500 hover:bg-gray-300">
                <small className="medium-black">
                  {moment(attributes.repetition_from).isSame(moment(attributes.repetition_until))
                    ? convertDate(formatDate(attributes.repetition_from))
                    : convertDate(formatDate(attributes.repetition_from)) +
                      ' - ' +
                      convertDate(formatDate(attributes.repetition_until))}
                </small>

                <small className="primary-text-regular pt-2">
                  {formatTime(attributes.from_time)}
                  {' - '}
                  {formatTime(attributes.to_time)}
                </small>
              </div>
            );
          })}
        </div>

        <div className="flex justify-end mt-3">
          <button
            onClick={close}
            className="rounded-md border border-light-gray text-xs py-3 w-full md:w-32 bg-primary-color hover:text-gray-400 text-white font-normal">
            Close
          </button>
        </div>
      </div>
    </SweetAlert>
  );
}

export default enhancer(AssociatedAvailabilities);
