import { combineReducers } from 'redux';
import { Map } from 'immutable';

const metaDefault = {
  showHUD: false,
  sidebarShow: 'responsive',
  buttonLoading: false,
  uploadProgress: 0,
  uploadHUD: false,
  selectedTrainingType: null,
  invitedById: null,
  inviter: null
};

const userDefault = {
  currentUser: null,
  openMenu: false,
  sports: [],
  sportPositions: null,
};

function metaReducer(state = Map(metaDefault), action) {
  switch (action.type) {
    case 'SHOW_HUD':
      return state.set('showHUD', true);

    case 'ANIMATE_BUTTON_LOADING':
      return state.set('buttonLoading', action.payload.value);

    case 'HIDE_HUD':
      return state.set('showHUD', false);

    case 'SET_USER_TYPE':
      return state.set('userType', action.payload.value);

    case 'SET_SIDE_BAR':
      return state.set('sidebarShow', action.payload.value);

    case 'SET_UPLOAD_PROGRESS':
      return state.set('uploadProgress', action.payload.setUploadProgress);

    case 'SET_UPLOAD_HUD':
      return state.set('uploadHUD', action.payload.value);

    case 'SET_DEFAULT_TRAINING_TYPE':
      return state.set('selectedTrainingType', action.payload.value);

    case 'SET_INVITED_BY_ID':
      return state.set('invitedById', action.payload.id);
    case 'SET_INVITER':
      return state.set('inviter', action.payload)

    default:
      return state;
  }
}

const currentUserReducer = (state = Map(userDefault), action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return state.set('currentUser', action.payload);

    case 'CHANGE_MENU_STATE':
      return state.set('openMenu', action.payload.value);

    case 'SET_SPORTS': {
      let arr = action.payload.value;
      let updated = arr.map(item => {
        return { label: item.attributes.name, value: item.id };
      });
      return state.set('sports', updated);
    }

    case 'SET_SPORT_POSITIONS':
      return state.set('sportPositions', action.payload.positions);

    default:
      return state;
  }
};

export default combineReducers({
  meta: metaReducer,
  current: currentUserReducer,
});
