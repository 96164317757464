import React from 'react';
import { Form } from 'react-bootstrap';
import { SearchLocationInput, InputLabel } from '../../Shared';

const ServiceLocation = ({
  locationValue,
  addressValue,
  hideRemove,
  onUpdateLocations,
  id,
  allLocations,
  removeLocation,
  totalCharacters
}) => {
  const onChange = (tag, value) => {
    onUpdateLocations(id, tag, value);
  };

  return (
    <div className="w-full my-1 items-center flex flex-col md:flex-row">
      <div className="w-full md:w-1/2 pr-3 flex flex-col items-start">
        <InputLabel label={`Training Location (${totalCharacters}/35 characters)`} color={totalCharacters === 35 && 'red'} />

        <Form.Control
          type="text"
          maxLength={35}
          className="font-sf-regular bg-transparent text-black text-xs"
          onChange={e => onChange('location', e.target.value)}
          value={locationValue}
          placeholder="i.e. City Field"
          required={true}
        />
      </div>

      <div className="w-full mt-0 md:mt-1 md:w-1/2 flex flex-col items-start">
        <InputLabel label="Address" />

        <SearchLocationInput
          value={addressValue}
          required={true}
          setLoc={loc => {
            onChange('address', loc);
          }}
          setParseSearch={search => { }}
        />
      </div>

      {(hideRemove && allLocations && allLocations.length > 1) ||
        (!hideRemove && (
          <small className="medium-black cursor-pointer mx-1" onClick={() => removeLocation(id)}>
            x
          </small>
        ))}
    </div>
  );
};

export default ServiceLocation;
