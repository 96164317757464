import React from 'react';
import Switch from 'react-input-switch';
import { ifTrainingHasLocation, notify } from '../../utils/utilities';

function ToggleButton({ handleOnToggle, status, showText, training, ...props }) {
  const checkStatusValidity = (id, status) => {
    ifTrainingHasLocation(training.training_type, training.provided_service_locations)
      ? handleOnToggle(id, status)
      : notify('Please add a training location first.');
  };

  return (
    <Switch
      styles={{
        track: {
          backgroundColor: 'gray',
          width: '40px',
          height: '23px',
          borderRadius: 20,
        },
        trackChecked: {
          backgroundColor: '#042E60',
        },
        button: {
          backgroundColor: 'white',
          height: '20px',
          width: '20px',
          borderRadius: '10px',
        },
        buttonChecked: {
          backgroundColor: 'white',
          marginLeft: '8px'
        },
      }}
      value={status === 'published'}
      on={true}
      off={false}
      onChange={e => checkStatusValidity(props.id, status)}
    />
  );
}

export default ToggleButton;
