import React, { useState, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

function ReferralCode({ show, onConfirm, hideReferralCode }) {
  const [code, setCode] = useState(null);

  useEffect(() => {
    setCode(null);
  }, [show]);

  const validate = () => {
    if (!code) return;
    else onConfirm(code);
  };

  return (
    <SweetAlert show={show} showConfirm={false} onCancel={hideReferralCode}>
      <div className="px-4">
        <h5 className="text-primary-color font-sf-medium">Invitation Code</h5>

        <small className="text-xs text-t-gray font-sf-regular">
          Please enter the invitation code which you have recieved from the other trainer.
        </small>

        <div className="justify-start items-start flex flex-col mt-3">
          <input
            className="text-input form-control"
            type="text"
            autoFocus
            onChange={e => setCode(e.target.value)}
            required
            value={code}
          />
        </div>

        <div className="flex justify-end my-8">
          <button onClick={validate} className="primary-dark-button p-3">
            Confirm
          </button>
        </div>
      </div>
    </SweetAlert>
  );
}

export default ReferralCode;
