import React from 'react';
import { Form } from 'react-bootstrap';
import { InputLabel } from '../Shared';
import { getSocialUrl } from '../../utils/utilities';

const LineConnector = ({ connection }) => {
  return (
    <div className="flex items-end w-full overflow-hidden mr-3">
      <div className="w-full h-8 line-connector" />

      <small className="text-gray-600 text-xs font-sf-regular px-1">{connection}</small>
    </div>
  );
};

function SocialLink({ id, value, handleOnChange }) {
  return (
    <div>
      <Form.Group controlId={id} className="flex flex-col justify-center">
        <InputLabel label={id} color="black" bold />

        <div className="flex items-end pl-4">
          <LineConnector connection={getSocialUrl(id.toLowerCase())} />

          <Form.Control
            type="text"
            className="font-sf-regular text-xxs"
            placeholder={
              id && id === 'Website'
                ? 'Enter website address'
                : `Enter ${id && id.toLowerCase()} username`
            }
            onChange={e => handleOnChange(id.toLowerCase(), e.target.value)}
            value={value}
          />
        </div>
      </Form.Group>
    </div>
  );
}

export default SocialLink;
