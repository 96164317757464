import { combineReducers } from 'redux';
import User from './User/reducers';
import Bookings from './Bookings/reducers';
import Calendar from './Availability/reducers';
import Service from './Services/reducers';

const appReducer = combineReducers({
  User,
  Bookings,
  Calendar,
  Service,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
