import React from 'react';
import { backgroundColor } from '../../utils/utilities';

function TrainingBadge({ trainingType, fullTrainings, large }) {
  return (
    <div className={`flex items-center self-center ${large && 'my-1'}`}>
      <div
        className={`h-2 w-2 rounded-full ${backgroundColor(trainingType)} ${large && 'h-3 w-3'}`}
      />

      <small
        className={`text-black font-sf-regular ml-1 ${fullTrainings ? 'uppercase' : 'capitalize'}`}>
        {trainingType}
      </small>
    </div>
  );
}

export default TrainingBadge;
