import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from '../utils/utilities';
import { CFade } from '@coreui/react';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={props =>
        isLoggedIn() ? (
          <CFade>
            <Component {...props} />{' '}
          </CFade>
        ) : (
          <CFade>
            {' '}
            <Redirect to="/" />
          </CFade>
        )
      }
    />
  );
};

export default PrivateRoute;
