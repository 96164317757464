import { combineReducers } from 'redux';
import { Map } from 'immutable';

const bookingsDefault = {
  bookings: [],
  booking: null,
  bookingsPagy: null,

  rejectedBookings: [],
  bookingRequest: null,
  bookingRequestPagy: null,

  //Payments
  payments: [],
  payment: null,
  paymentPagy: null,
};

const toNumber = string => +string;

const bookingsReducer = (state = Map(bookingsDefault), action) => {
  switch (action.type) {
    case 'SET_BOOKINGS':
      return state.set('bookings', action.payload.data);

    case 'SET_BOOKINGS_PAGY':
      return state.set('bookingsPagy', action.payload.data);

    case 'SET_SINGLE_BOOKING':
      return state.set('booking', action.payload.data);

    case 'UPDATE_BOOKING':
      return state.update('bookings', bookings =>
        bookings.map(booking => {
          if (booking.attributes.id === toNumber(action.payload.id))
            booking.attributes = action.payload.data.attributes;

          return booking;
        })
      );

    case 'SET_BOOKING_REQUESTS':
      return state.set('rejectedBookings', action.payload.data);

    case 'SET_BOOKING_REQUEST_PAGY':
      return state.set('bookingRequestPagy', action.payload.data);

    case 'SET_SINGLE_REQUEST':
      return state.set('bookingRequest', action.payload.data);

    case 'UPDATE_REQUEST':
      return state.update('rejectedBookings', bookings =>
        bookings.map(booking => {
          if (booking.id === action.payload.id) booking.attributes = action.payload.data.attributes;

          return booking;
        })
      );

    case 'FILTER_REQUEST': {
      return state.update('rejectedBookings', bookings =>
        bookings.filter(item => {
          return item.id.toString() !== action.payload.id.toString();
        })
      );
    }

    //==============Payments=================
    case 'SET_PAYMENTS': {
      return state.set('payments', action.payload.data);
    }

    case 'SET_SINGLE_PAYMENT':
      return state.set('payment', action.payload.data);

    case 'SET_PAYMENTS_PAGY':
      return state.set('paymentPagy', action.payload.data);

    default:
      return state;
  }
};

export default combineReducers({
  booking: bookingsReducer,
});
