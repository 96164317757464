import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserActions } from '../../../store/actions';
import LoginForm from './LoginForm';
import { LogoRound } from '../../../assets/img';
import { ReferralCode } from '../SignUp';
import { notify, getConsumerUrl } from '../../../utils/utilities';

let connectProps = {
  ...UserActions,
};

let enhancer = connect(null, connectProps);

function Signin(props) {
  const [loader, setLoader] = useState(false);
  const [openReferralPopup, setReferralPopup] = useState(false);

  useEffect(() => {
    props.resetInvitedById();
  }, []);

  const submitSignin = async data => {
    const email = data.email;
    const password = data.password;
    setLoader(true);
    await props.signIn({ email, password });
    setLoader(false);
    props.getSports();
  };

  const validateSignup = () => {
    setReferralPopup(true);
  };

  const checkReferralCodeValid = async code => {
    setReferralPopup(false);
    props.checkReferralCodeValid(code).then(response => {
      response.inviter ? history.push('/athlete/signup') : notify('Invalid invitation code.');
    });
  };

  let history = useHistory();
  return (
    <div className="min-h-screen bg-main-background pt-3">
      <img className="object-cover h-12 mx-4" src={LogoRound} />

      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto my-10">
        <div
          className="w-full md:w-1/3 overflow-hidden rounded-lg shadow-md mx-4 md:mx-0 bg-white"
          style={{ minHeight: '480px' }}>
          <div className="flex w-full justify-between">
            <button
              className="w-1/2 rounded-md border border-gray-500 text-xs py-3 bg-white hover:text-blue-500 text-primary-color font-sf-regular md:my-0 focus:outline-none"
              onClick={() => {
                window.location.replace(getConsumerUrl());
              }}>
              For Players
            </button>

            <button
              disabled
              className="cursor-not-allowed rounded-md border-2 border-primary-color text-xs py-3 w-1/2 bg-white hover:text-blue-500 text-primary-color font-sf-bold md:my-0 focus:outline-none">
              For Trainers
            </button>
          </div>

          <LoginForm
            loader={loader}
            loginParent={data => submitSignin(data)}
            resetPassword={() => history.push('/login/reset')}
            signupParent={validateSignup}
          />

          <ReferralCode
            show={openReferralPopup}
            hideReferralCode={() => setReferralPopup(false)}
            onConfirm={checkReferralCodeValid}
          />
        </div>
      </div>
    </div>
  );
}

export default enhancer(Signin);
