import React from 'react';

function PaymentAlertBanner({ name }) {
  return (
    <div className="w-full flex text-black bg-alert font-bold p-3 text-center mt-1 position-sticky top-0 z-10 justify-center">
      <p className="mb-0">
        Hi {name} to receive payments,
        <a href="/card" className="mx-1">
          Click Here
        </a>
        to add your bank account
      </p>
    </div>
  );
}

export default PaymentAlertBanner;
