import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

function AvailabilityAlert({ disableAvailabilityAlert }) {
  return (
    <SweetAlert
      style={{ position: 'absolute' }}
      show={true}
      confirmBtnText="Got it"
      confirmBtnBsStyle="success"
      title="Turn on the training"
      onConfirm={() => {
        disableAvailabilityAlert(false);
      }}>
      <small className="text-left">
        To create a new availability time slot, you need to have at least one Training Option
        created and turned ON.
      </small>
    </SweetAlert>
  );
}

export default AvailabilityAlert;
