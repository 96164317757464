import React from 'react';
import { Banner } from '../../Shared';
import { getConsumerUrl } from '../../../utils/utilities';

const AthleteInfo = ({
  currentUser,
  history,
  onboarding,
  createAvailability,
  createTraining,
  bookingLinkOnly,
  inviteOnly,
}) => {
  let athlete = currentUser && currentUser.attributes;
  return (
    <div>
      {athlete && (
        <div className="flex items-center justify-between mt-0 mb-2 flex-wrap">
          <div
            className={`flex flex-row w-full ${!onboarding && 'md:w-1/4'} ${
              !onboarding && 'share-profile-step'
            }`}>
            <div className={`flex flex-col ${onboarding ? 'items-center mt-2' : 'items-start'}`}>
              <small
                className={`font-sf-semibold text-primary-color capitalize ${
                  onboarding ? 'text-sm' : 'text-xs'
                }`}>
                {athlete.first_name} {athlete.last_name}
              </small>

              {bookingLinkOnly && (
                <small
                  className={`primary-text-regular normal-case text-t-gray ${
                    onboarding ? 'text-sm' : 'text-xs'
                  }`}>
                  Booking Page:{' '}
                  <a
                    className="font-sf-regular text-xs text-blue-500"
                    href={`${getConsumerUrl()}/${athlete.slug}`}
                    target="_blank">
                    {`trayvo.com/${athlete.slug}`}
                  </a>
                </small>
              )}

              {inviteOnly && (
                <small
                  className={`primary-text-regular normal-case text-t-gray ${
                    onboarding ? 'text-sm' : 'text-xs'
                  }`}>
                  Invite Code:{' '}
                  <small className="primary-text-regular normal-case">
                    {athlete.referral_code && athlete.referral_code.name}
                  </small>
                </small>
              )}
            </div>
          </div>

          {!onboarding && (
            <div className="hide-element md:w-1/2">
              <Banner
                createAvailability={createAvailability}
                createTraining={createTraining}
                bookingLink={`${getConsumerUrl()}/${athlete.slug}`}
              />
            </div>
          )}

          <div
            className={`${
              onboarding ? 'hidden' : 'flex md:flex-col items-start md:items-end w-full md:w-1/4'
            }`}>
            <div>
              <small className="primary-text-regular text-t-gray md:text-primary-color md:font-semibold text-xs">
                Current Location
                <small className="md:hidden text-black pr-1">:</small>
              </small>
            </div>

            <button
              type="button"
              onClick={() => history.push('/profile/edit')}
              className="focus:outline-none">
              <small className="text-blue-500 hover:text-blue-800 primary-text-regular">
                {athlete.city}, {athlete.state}
              </small>
            </button>
          </div>

          {!onboarding && (
            <div className={`w-full visible md:w-1/2 md:hidden`}>
              <Banner
                createAvailability={createAvailability}
                createTraining={createTraining}
                bookingLink={`${getConsumerUrl()}/${athlete.slug}`}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AthleteInfo;
