import React, { useEffect } from 'react';
import { formatTime, backgroundColor } from '../../../utils/utilities';
import moment from 'moment';
import { entryColor } from '../../../constants';

const MonthCalendar = ({
  calendarEntry,
  showDetails,
  id,
  sTime,
  eTime,
  getTrainingType,
  moveToDetail,
  showPopup,
}) => {
  const getColor = colorType => {
    return colorType === 'virtual_color'
      ? entryColor.VIRTUALCOLOR
      : colorType === 'group_color'
      ? entryColor.GROUPCOLOR
      : entryColor.INDIVIDUALCOLOR;
  };

  const truncate = str => {
    return str.length > 20 ? str.substring(0, 20) + '...' : str;
  };

  const isFutureEntry = date => {
    return date >= moment().subtract(1, 'days').format();
  };

  return calendarEntry ? (
    <div>
      <div
        onClick={() => showDetails(id)}
        style={{
          borderWidth: 1,
          paddingTop: '1px',
          paddingBottom: '1px',
          borderColor: getColor(calendarEntry.entry_color),
        }}
        className={`flex flex-wrap flex-row w-full justify-between pl-1 rounded items-center  ${
          isFutureEntry(calendarEntry.booking_date) ? '' : 'opacity-50'
        }`}>
        <div className="flex justify-between" style={{ width: '80%' }}>
          <small
            className={'font-sf-semibold'}
            style={{
              fontSize: 9,
              color: getColor(calendarEntry.entry_color),
            }}>
            {getTrainingType(calendarEntry.entry_color)}
          </small>

          <small
            className={'font-sf-semibold'}
            style={{
              fontSize: 9,
              color: getColor(calendarEntry.entry_color),
            }}>
            {sTime}-{eTime}
          </small>
        </div>

        <div className="pr-1 justify-end content-end flex" style={{ width: '20%' }}>
          {isFutureEntry(calendarEntry.booking_date) && (
            <button
              onClick={e => {
                e.stopPropagation();
                showPopup();
              }}
              class="bg-blue-500 hover:bg-red-700 text-white text-xs font-bold rounded">
              <small className="px-1">X</small>
            </button>
          )}
        </div>
      </div>

      <div className="bg-transparent">
        {calendarEntry.bookings &&
          calendarEntry.bookings.data.map(booking => {
            let training = booking.attributes.provided_service.data.attributes;
            return (
              <div
                className="flex flex-wrap justify-between hover:bg-gray-500"
                onClick={() => moveToDetail(booking.id)}>
                <div className="md:w-1/2 flex items-center overflow-hidden text-left">
                  <div
                    className={`h-1 w-1 rounded-full ${backgroundColor(training.training_type)}`}
                  />

                  <small
                    style={{ fontSize: 9 }}
                    className="px-1 text-primary-color capitalize font-sf-regular text-xs">
                    {truncate(training.name)}
                  </small>
                </div>

                <div className="md:w-1/2 text-right">
                  <small
                    style={{ fontSize: 9 }}
                    className="px-1 text-primary-color text-right font-sf-regular text-xs">
                    {formatTime(booking.attributes.from_time)}-
                    {formatTime(booking.attributes.to_time)}
                  </small>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  ) : (
    <div />
  );
};

export default MonthCalendar;
