import React from 'react';

function SelectionButton({ value, onClick, days, text, checked }) {
  return (
    <div className="w-full pr-1">
      <button
        type={'button'}
        onClick={() => onClick(value)}
        className={`uppercase  rounded text-xs text-gray-600 border-2 w-full hover:shadow-md outline-none focus:outline-none ${
          checked ? 'border-primary-color' : 'border-gray-300 opacity-75'
        } ${days ? 'py-1' : 'py-2'}`}>
        <small
          className={`text-xs px-1 ${days && 'px-3'} ${
            checked ? 'text-primary-color' : 'text-gray-600'
          } capitalize`}>
          {text}
        </small>
      </button>
    </div>
  );
}

export default SelectionButton;
