import React, { useEffect, useState, useMemo, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import {
  UserActions,
  ServiceActions,
  BookingsActions,
  CalendarActions,
} from '../../../store/actions';
import { Tabs, Tab } from 'react-bootstrap';
import { BookingsMini } from '../Bookings';
import { useHistory } from 'react-router-dom';
import { DateSelection, EditAvailability, AvailabilityAlert } from '../../Popup';
import FilteredBookings from './FilteredBookings';
import { DashboardAvailabilityMini } from '../Availability/';
import CalendarEntries from '../../../hocs/CalendarEntries';
import { ColorIndication, TooltipIcon } from '../../Shared';
import { NewTraining, EditTraining } from '../Services';
import { DashboardTrainings, AthleteInfo } from './index';
import { accentColor } from '../../../constants';
import { Loader } from '../Loader';
import ScrollUpButton from 'react-scroll-up-button';
import Tour from 'reactour';
import {
  tourGuide,
  availTooltip,
  calendarTooltip,
  trainingsTooltip,
} from '../../../stringConstants';
import { ifTrainingsPublished, getTrainingTypes, notify } from '../../../utils/utilities';
const Calendar = lazy(() => import('../Availability/Calendar'));
const Schedule = lazy(() => import('../../Athlete/Schedule/Schedule'));
const DashboardAvailability = lazy(() => import('../Availability/DashboardAvailability'));

let connectProps = {
  ...UserActions,
  ...BookingsActions,
  ...ServiceActions,
  ...CalendarActions,
};

let connectState = state => ({
  currentUser: state.User.current.get('currentUser'),
  bookings: state.Bookings.booking.get('bookings'),
  providedServices: state.Service.service.get('providedServices'),
  providedServicesPagy: state.Service.service.get('providedServicesPagy'),
  buttonLoading: state.User.meta.get('buttonLoading'),
  loader: state.User.meta.get('showHUD'),
  services: state.Service.service.get('services'),
  selectedTrainingType: state.User.meta.get('selectedTrainingType'),
  availabilities: state.Calendar.calendar,
  availabilityPagy: state.Calendar.calendar,
  selectedTraining: state.Calendar.calendar.get('selectedTraining'),
});

let enhancer = connect(connectState, connectProps);

function Dashboard(props) {
  const history = useHistory();
  const [showGuide, setShowGuide] = useState(false);
  const [availabilityAlert, setAvailabilityAlert] = useState(false);
  const [tab, setTab] = useState('/calendar');
  const [availTab, setAvailTab] = useState('/availability');
  const [newTrainingPopup, setNewTrainingPopup] = useState(null);
  const [editTrainingPopup, setEditTrainingPopup] = useState(null);
  const [loaderMain, setLoaderMain] = useState(false);
  const [isDayView, setCalendarView] = useState(false);

  let {
    getBookings,
    getAvailabilities,
    getProvidedServices,
    currentUser,
    loader,
    providedServicesPagy,
    selectedTrainingType,
  } = props;

  useEffect(() => {
    let { providedServices, getSportPositions } = props;
    let availabilities = props.availabilities.toJS().availabilities || [];

    (async () => {
      setLoaderMain(true);
      availabilities.length === 0 && (await getAvailabilities());
      providedServices.length === 0 && (await getProvidedServices());
      setLoaderMain(false);
    })();

    if (currentUser && currentUser.attributes) {
      getSportPositions(currentUser.attributes.sport_id);
      if (currentUser.attributes.first_visit) setShowGuide(true);
    }
  }, []);

  useMemo(() => {
    if (
      newTrainingPopup ||
      editTrainingPopup ||
      props.dateSelectionModal ||
      props.editAvailability
    ) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else document.body.style.overflow = 'unset';
  }, [newTrainingPopup, editTrainingPopup, props.dateSelectionModal, props.editAvailability]);

  const handleOnToggle = async (id, status) => {
    props.changeButtonLoading(true);
    await props.updateProvidedService(id, {
      provided_service: {
        status: status === 'published' ? 'unpublished' : 'published',
      },
    });
    props.changeButtonLoading(false);
  };

  const deleteTraining = async id => {
    await props.deleteProvidedService(id);
    notify('Deleted successfully', 'success');
  };

  const setSelected = tab => {
    setTab(tab);
    getBookings();
  };

  const openCreateAvail = () => {
    let { providedServices, openDateSelection } = props;
    ifTrainingsPublished(providedServices) ? openDateSelection() : setAvailabilityAlert(true);
  };

  const getBulkTrainings = async () => {
    await getProvidedServices(1, 100);
  };

  let updatedEntries = props.availabilities.toJS().entries;

  let providedServices =
    props.providedServices && props.providedServices.filter(item => !item.attributes.deleted);
  if (providedServices.length <= 3)
    providedServices.push({ attributes: { deleted: false, title: 'new' } });

  return (
    <div className="bg-main-background w-full">
      {!loaderMain ? (
        <div className="flex flex-col m-1 md:m-4 rounded-md px-3">
          <AthleteInfo
            currentUser={currentUser}
            history={history}
            createAvailability={openCreateAvail}
            createTraining={setNewTrainingPopup}
            bookingLinkOnly
            inviteOnly
          />

          <div className="flex flex-col md:flex-row mt-3">
            <div className="flex flex-col w-full md:w-1/2 trainings-width">
              {providedServices.length > 0 && (
                <div className="rounded-lg flex flex-col">
                  <div
                    className="flex items-center justify-between bg-white rounded-lg shadow-md mb-1"
                    style={{ height: '50px' }}>
                    <div className="flex items-center">
                      <small className="font-sf-semibold pl-4 text-sm text-blue-800">
                        Trainings
                      </small>
                      <TooltipIcon tooltipText={trainingsTooltip} id="trainings" />
                    </div>

                    <div className="flex">
                      <button
                        type="button"
                        onClick={() => {
                          setNewTrainingPopup(true);
                        }}
                        className="primary-button bg-primary-color text-white new-training-step mx-1 shadow-md">
                        <small className="font-sf-regular text-xs">New Training</small>
                      </button>

                      <button
                        onClick={() => {
                          history.push('trainings');
                        }}
                        className="font-sf-regular hover:text-blue-500 text-primary-color text-xs py-2 px-1 self-end mx-2 mini-hide">
                        View all
                        <strong className="ml-1">{'>'}</strong>
                      </button>
                    </div>
                  </div>

                  <DashboardTrainings
                    {...props}
                    providedServices={providedServices}
                    setEditTrainingPopup={setEditTrainingPopup}
                    deleteTraining={deleteTraining}
                    handleOnToggle={handleOnToggle}
                    setNewTrainingPopup={setNewTrainingPopup}
                  />
                </div>
              )}
            </div>

            <div className="flex flex-col w-full md:w-1/2 desktop-view relative ml-5">
              <div className="absolute right-0 pr-3" style={{ top: 5 }}>
                <button
                  type="button"
                  onClick={() => {
                    props.setSelectedTraining(null);
                    props.setDefaultTrainingType(null);

                    openCreateAvail();
                  }}
                  className="primary-button bg-primary-color text-white new-training-step mx-1 shadow-md create-availability-step shadow-md">
                  Add Availability
                </button>
              </div>

              <Tabs
                className="flex items-center dashboard-tabs bg-white shadow-md rounded-lg"
                defaultActiveKey="/availability"
                unmountOnExit={true}
                style={{ height: '50px' }}
                activeKey={availTab}
                onSelect={tab => setAvailTab(tab)}>
                <Tab
                  eventKey="/availability"
                  tabClassName="text-primary-color hover:text-blue-500 text-xs font-sf-regular"
                  title={
                    <span className="flex items-center">
                      Availability
                      <TooltipIcon tooltipText={availTooltip} id="avail" />
                    </span>
                  }>
                  <div>
                    <Suspense fallback={<div>Loading...</div>}>
                      <DashboardAvailability {...props} loader={loader} />
                    </Suspense>
                  </div>
                </Tab>

                <Tab
                  eventKey="/bookings"
                  tabClassName="text-primary-color hover:text-blue-500 text-xs font-sf-regular"
                  title="Bookings">
                  <div className="flex flex-col">
                    <FilteredBookings />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>

          <div className="desktop-view mt-4">
            <Tabs
              className="flex items-center dashboard-tabs bg-white h-12 rounded-md"
              defaultActiveKey="/calendar"
              activeKey={tab}
              onSelect={tab => setSelected(tab)}>
              <Tab
                eventKey="/calendar"
                tabClassName="text-primary-color hover:text-blue-300"
                title={
                  <span className="flex items-center">
                    Calendar
                    <TooltipIcon tooltipText={calendarTooltip} id="calendar" />
                  </span>
                }>
                <div className="w-full relative mt-1">
                  <ColorIndication />

                  <div className="flex flex-wrap">
                    <div className="w-full calendar-step">
                      <Suspense fallback={<div>Loading...</div>}>
                        <Calendar
                          fromNewFlow={true}
                          setCalendarView={value => setCalendarView(value)}
                          setCurrentMonth={props.setCurrentMonth}
                          updatedEntries={updatedEntries}
                          history={history}
                          trainings={providedServices}
                        />
                      </Suspense>
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab
                eventKey="/schedule"
                tabClassName="text-primary-color hover:text-blue-300"
                title="Schedule">
                <div className="bg-main-background mb-4 flex flex-col">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Schedule history={history} tab="/schedule" activeTab={tab} />
                  </Suspense>
                </div>
              </Tab>
            </Tabs>
          </div>

          <div className="hidden mini-view mx-0">
            <Tabs
              className="dashboard-tabs bg-white mt-3 w-full focus:outline-none"
              defaultActiveKey="/bookings"
              activeKey={tab}
              onSelect={tab => setSelected(tab)}>
              <Tab
                eventKey="/calendar"
                tabClassName="text-primary-color hover:text-blue-300"
                title={
                  <span className="flex items-center">
                    Availability
                    <TooltipIcon tooltipText={availTooltip} id="avail" />
                  </span>
                }>
                <div className="font-sf-regular">
                  <div className="flex justify-between">
                    <button
                      type="button"
                      onClick={() =>
                        ifTrainingsPublished(providedServices)
                          ? props.openDateSelection()
                          : setAvailabilityAlert(true)
                      }
                      style={{ padding: 12 }}
                      className="primary-button create-availability-step bg-highlight-color shadow-md mt-3">
                      Add Availability
                    </button>
                  </div>

                  <DashboardAvailabilityMini {...props} loader={loader} />
                </div>
              </Tab>

              <Tab
                eventKey="/bookings"
                tabClassName="text-primary-color hover:text-blue-300"
                title="Bookings">
                <BookingsMini searchText="" {...props} />
              </Tab>
            </Tabs>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center content-center min-h-screen">
          <Loader />
        </div>
      )}

      {props.dateSelectionModal && (
        <DateSelection
          {...props}
          closeModal={() => props.closeDateSelection()}
          providedServices={getTrainingTypes(providedServices)}
          trainings={providedServices}
          trainingsPagy={providedServicesPagy}
          getBulkTrainings={getBulkTrainings}
          selectedTrainingType={selectedTrainingType}
        />
      )}

      {props.editAvailability && (
        <EditAvailability
          isVisible
          refreshModal={props.refreshModal}
          availId={props.availId}
          updateFrom={props.updateFrom}
          updateTo={props.updateTo}
          updateRangeFrom={props.updateRangeFrom}
          updateRangeTo={props.updateRangeTo}
          optionType={props.optionType}
          repCount={props.repCount}
          closeModal={() => props.closeDateSelection()}
          buttonLoading={props.buttonLoading}
          apply={props.applyDateSelection}
          days={props.days}
          trainings={providedServices}
          trainingsPagy={providedServicesPagy}
          training_type={props.training_type}
          selected_trainings={props.selected_trainings}
          training_color={props.training_color}
          providedServices={getTrainingTypes(providedServices)}
          getBulkTrainings={getBulkTrainings}
        />
      )}

      {newTrainingPopup && (
        <NewTraining
          {...props}
          closePopup={choice => {
            setNewTrainingPopup(null);
            if (choice && choice.showAvailabilityPopup) openCreateAvail();
          }}
        />
      )}

      {editTrainingPopup && (
        <EditTraining
          serviceId={editTrainingPopup}
          isVisible={editTrainingPopup}
          closePopup={choice => {
            setEditTrainingPopup(null);
            if (choice && choice.showAvailabilityPopup) openCreateAvail();
          }}
        />
      )}

      {availabilityAlert && (
        <AvailabilityAlert disableAvailabilityAlert={() => setAvailabilityAlert(false)} />
      )}

      <ScrollUpButton />

      <Tour
        steps={tourGuide}
        isOpen={showGuide}
        onRequestClose={() => {
          setShowGuide(false);
          props.updateFirstVisit(false);
        }}
        rounded={5}
        accentColor={accentColor}
      />
    </div>
  );
}

export default enhancer(CalendarEntries(Dashboard));
