import api from '../api';
import { setToken, unsetToken } from './helpers';
import { client } from '../index';
import qs from 'qs';
import { RESET_PASSWORD_URL } from '../../constant';
import { timeZoneOffset } from '../../utils/utilities';

export const showHUD = () => {
  return { type: 'SHOW_HUD' };
};

export const hideHUD = () => {
  return { type: 'HIDE_HUD' };
};

export const setCurrentUser = (data, OB = false) => {
  let user = data;
  if (!OB) localStorage.setItem('auth_token', user.attributes.auth_token);
  setToken(user.attributes.auth_token);
  return {
    type: 'SET_CURRENT_USER',
    payload: user,
  };
};

export const signUp = payload => async dispatch => {
  dispatch(showHUD());
  const { data } = await client().post(api.signUp(), { athlete: payload });
  dispatch(hideHUD());
  dispatch(setCurrentUser(data.data, true));
  return data.data;
};

export const signIn = payload => async dispatch => {
  dispatch(showHUD());
  const { data } = await client().post(api.signIn(), payload);
  dispatch(hideHUD());
  dispatch(setCurrentUser(data.data));
};

export const logoutPressed = () => async dispatch => {
  let user = {};
  unsetToken();
  dispatch({ type: 'SET_CURRENT_USER', payload: { user } });
  dispatch({ type: 'RESET_STATE' });
};

export const refreshAthlete = () => async dispatch => {
  const { data } = await client().get(api.viewProfile());
  dispatch(setCurrentUser(data.data));
};

export const changeMenuState = value => async dispatch => {
  dispatch({ type: 'CHANGE_MENU_STATE', payload: { value } });
};

export const changeButtonLoading = value => async dispatch => {
  dispatch({ type: 'ANIMATE_BUTTON_LOADING', payload: { value } });
};

export const setDefaultTrainingType = value => async dispatch => {
  dispatch({ type: 'SET_DEFAULT_TRAINING_TYPE', payload: { value } });
};

export const createAccount = payload => async dispatch => {
  dispatch(showHUD());
  const { data } = await client().post(api.account(), payload);
  dispatch(hideHUD());
  return data;
};

export const updateAccount = payload => async dispatch => {
  const { data } = await client().put(api.account(), payload);
  return data;
};

export const editProfile = payload => async dispatch => {
  dispatch(showHUD());
  const { data } = await client().put(api.viewProfile(), payload);
  dispatch(refreshAthlete());
  dispatch(hideHUD());
  return data;
};

export const updateFirstVisit = value => async dispatch => {
  let payload = {
    athlete: {
      first_visit: false,
    },
  };
  dispatch(showHUD());
  const { data } = await client().put(api.viewProfile(), payload);
  dispatch(refreshAthlete());
  dispatch(hideHUD());
  return data;
};

export const getProfileSignedUrl = () => async dispatch => {
  const { data } = await client().get(api.profileSignedUrl());
  return data;
};

export const uploadImage = (file, signedUrl) => async dispatch => {
  const options = {
    headers: {
      'Content-Type': file.type,
      'x-amz-acl': 'public-read',
    },
  };
  const response = await client().put(signedUrl, file, options);
  return response;
};

export const getVideoSignedUrl = extension => async dispatch => {
  const { data } = await client().get(api.videoSignedUrl(extension));
  return data;
};

export const setUploadProgress = setUploadProgress => async dispatch => {
  dispatch({ type: 'SET_UPLOAD_PROGRESS', payload: { setUploadProgress } });
};

export const showUploadHUD = value => async dispatch => {
  dispatch({ type: 'SET_UPLOAD_HUD', payload: { value } });
};

export const uploadVideo = (file, signedUrl) => async dispatch => {
  const options = {
    headers: {
      'Content-Type': file.type,
      'x-amz-acl': 'public-read',
    },
  };
  const response = await client().put(signedUrl, file, options);
  dispatch(showUploadHUD(false));
  dispatch(setUploadProgress(0));
  return response;
};

export const checkValidity = param => async dispatch => {
  let paramsString = qs.stringify(param);
  const { data } = await client().get(`${api.checkValid()}?${paramsString}`);
  return data;
};

export const getResetPassword = email => async dispatch => {
  let param = {
    athlete: {
      email,
      url: RESET_PASSWORD_URL,
    },
  };
  let paramsString = qs.stringify(param);
  const { data } = await client().get(`${api.sendResetPassword()}?${paramsString}`);
  return data;
};

export const updateWithEmail = payload => async dispatch => {
  const { data } = await client().post(api.resetPassword(), payload);
  return data;
};

export const updateWithToken = payload => async dispatch => {
  const { data } = await client().post(api.resetPassword(), payload);
  return data;
}

export const updateWithCurrentPassword = payload => async dispatch => {
  const { data } = await client().post(api.updatepassword(), payload);
  return data;
};

export const getSchoolsBySearch = payload => async dispatch => {
  let paramsString = qs.stringify(payload);

  const { data } = await client().get(`${api.getSchools()}?${paramsString}`);
  return (
    data.data &&
    data.data.map(({ id, attributes }) => {
      return {
        label: `${attributes.nickname} (${attributes.city + ', ' + attributes.state})`,
        value: id,
      };
    })
  );
};

export const getCollegesBySearch = payload => async dispatch => {
  let paramsString = qs.stringify(payload);
  const { data } = await client().get(`${api.getColleges()}?${paramsString}`);
  return (
    data.data &&
    data.data.map(({ id, attributes }) => {
      return {
        label: `${attributes.nickname} (${attributes.city + ', ' + attributes.state})`,
        color: attributes.color_code,
        value: id,
      };
    })
  );
};

export const getSports = () => async dispatch => {
  const { data } = await client().get(api.getSports());
  let value = data.data;
  dispatch({ type: 'SET_SPORTS', payload: { value } });
  return data;
};

export const getSportPositions = id => async dispatch => {
  const { data } = await client().get(`${api.getSports()}/${id}`);
  let positions = data.data;
  dispatch({ type: 'SET_SPORT_POSITIONS', payload: { positions } });
  return data;
};

export const checkReferralCodeValid = code => async dispatch => {
  const { data } = await client().get(api.referralCode(code));
  if (data.inviter) dispatch({ type: 'SET_INVITER', payload: { id: data.inviter.id, type: data.inviter_type } });
  return data;
};

export const resetInvitedById = code => async dispatch => {
  dispatch({ type: 'SET_INVITED_BY_ID', payload: { id: null } });
  dispatch({ type: 'SET_INVITER', payload: null });
};
