import React from 'react';

function OnboardingAction({ navigateLogin, skip, hideSignIn, disableAction }) {
  return (
    <div
      className={`flex flex-col md:flex-row items-center ${
        hideSignIn ? 'justify-end' : 'justify-between'
      }   mt-3`}>
      {!hideSignIn && (
        <div className="flex items-center">
          <span className="text-black">Already have an account?</span>
          <button
            type="button"
            onClick={navigateLogin}
            className="font-sf-semibold underline text-blue-500 hover:text-gray-600 text-xs ml-1 focus:outline-none">
            Sign in.
          </button>
        </div>
      )}

      <div className="mt-3 md:mt-0 flex items-center">
        {skip && (
          <button
            type="submit"
            className="px-3 focus:outline-none underline rounded text-primary-color hover:text-blue-500 text-xs">
            Skip
          </button>
        )}

        <button
          type="submit"
          disabled={disableAction}
          className={`flex items-center justify-center border border-primary-color hover:bg-primary-color rounded-md 
          text-primary-color hover:text-white text-xs py-2 px-5 focus:outline-none
          ${disableAction ? 'cursor-not-allowed' : 'cursor-pointer'}
          `}>
          Continue
        </button>
      </div>
    </div>
  );
}

export default OnboardingAction;
