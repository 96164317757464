import React from 'react';
import { Link } from 'react-router-dom';
import {
  privacyPolicyProcessInfoTableData,
  privacyPolicyPersonalInfoDiscloseTableData,
} from '../../constants';
import { NormalText, Heading } from './Typography';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-main-background pt-3 text-black">
      <div className="flex mb-4 ml-4">
        <Link
          to="/"
          className="border-primary-color border-2 rounded-md mb-2 bg-transparent text-primary-color hover:text-primary-color hover:no-underline px-3 py-2 text-center focus:outline-none">
          Go Back
        </Link>
      </div>

      <div className="container mx-auto mb-3 md:mb-5">
        <h2 className="primary-text-regular text-center text-black md:text-4xl text-xl">
          TRAYVO HOLDINGS, LLC PRIVACY POLICY
        </h2>
        <hr />

        <div className="md:mx-5 mx-4">
          <p className="text-lg">
            <b>Effective Date:</b> May 10, 2021
          </p>

          <NormalText>
            This Privacy Policy describes information Trayvo Holdings, LLC (“Trayvo”, “us”, or “we”)
            may collect when you visit a website owned by Trayvo (the “Site”) or when you direct an
            inquiry to Trayvo through electronic means, telephonically, or otherwise. This Site is
            located at
            <a href="https://trainer.trayvo.com/" target="_blank" className="mx-1">
              trainer.trayvo.com
            </a>
            and is owned and operated by Trayvo. This Privacy Policy also describes information we
            may otherwise collect in the course of our business and discusses how Trayvo stores,
            uses, discloses or shares information collected. This Privacy Policy forms part of the
            Terms and Conditions of Use that govern your use of the Site.
          </NormalText>

          <NormalText>
            Please read this Privacy Policy carefully. If you do not agree, please do not access or
            use the Site. By accessing or using the Site, or by clicking “I agree,” you agree to
            this Privacy Policy. This Privacy Policy may change from time to time. Trayvo will post
            the modified version at this link. Your continued use of the Site after the Privacy
            Policy is modified is deemed to be acceptance of those modifications, so please check
            periodically for updates.
          </NormalText>

          <Heading>1. Scope</Heading>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b> This Privacy Policy tells you about our information
            gathering, use, storing, and disclosure practices and applies to information collected
            through your use of this Site and your electronic communications through this Site. This
            Privacy Policy also applies to our collection and processing of information provided to
            us or collected by us offline.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b> This Privacy Policy does not apply to information collected
            by third parties, including via websites that may be accessible from, link to, or are
            linked from this Site. This Privacy Policy does not apply to information collected by
            third parties, even if the Site is accessed through a third party website or app. Trayvo
            is not responsible for the content or privacy practices of any company other than
            Trayvo, and Trayvo is not responsible for the content or privacy practices of any
            non-Trayvo Site to which this Site links or which link to a Trayvo Site.
          </NormalText>

          <Heading className="inline">2. Information We Collect.</Heading>

          <NormalText classes="inline">
            Trayvo collects personal information and non-personal information.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b> “Non-personal Information” is any information that does not
            identify you individually or does not permit a direct association with an individual.
            Non-personal Information may include information derived from Personal Information that
            has been de-personalized by removing unique identifiers and/or combining it with
            information about other users to form aggregated data.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b> “Personal Information” is information that is reasonably
            capable of being associated or could be linked, directly or indirectly, with a
            particular consumer or household. Trayvo collects some information that is about you
            individually, but does not identify you, and collects information about your internet
            connection, your device used to access the Site, and your use of the Site. If Trayvo
            combines that information with personal information, it will treat it as personal
            information.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(c)</b> Examples of information Trayvo collects include:
          </NormalText>

          <NormalText classes="ml-5">
            <b className="mr-2">(i)</b> Registration information you submit when you create an
            account on the Site or update your account, including your name, email address, phone
            number, and mailing information or sign up to receive email correspondence from the
            Site;
          </NormalText>

          <NormalText classes="ml-5">
            <b className="mr-2">(ii)</b> Information you submit when you subscribe to the Site,
            provide us feedback, use our “submit a request” form or otherwise correspond with
            Trayvo, which may include copies of the correspondence;
          </NormalText>

          <NormalText classes="ml-5">
            <b className="mr-2">(iii)</b> Information we obtain from a third party, such as a third
            party site, vendor, service provider, analytics partner, or other platform provider;
          </NormalText>

          <NormalText classes="ml-5">
            <b className="mr-2">(iv)</b> Location information, including location information
            provided by a mobile device or other device as you navigate the Site;
          </NormalText>

          <NormalText classes="ml-5">
            <b className="mr-2">(v)</b> Activity information about your use of the Site;
          </NormalText>

          <NormalText classes="ml-5">
            <b className="mr-2">(vi)</b> Usage, viewing, technical, and device data when you visit
            the Site or interact with communications from our Site.
          </NormalText>

          <Heading className="inline">3. How We Collect.</Heading>

          <NormalText classes="inline">
            Trayvo collects information from and about you directly when you provide such
            information to Trayvo and automatically when you use the Site.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b> Voluntarily and/or Directly Provided. In the course of
            visiting and using the Site, you may provide information to us voluntarily. For example,
            you provide information to Trayvo voluntarily when you create an account. If you
            voluntarily provide your Personal Information to us, we collect it. You choose whether
            to disclose Personal Information, but you may not be able to use certain portions or
            features of the Site or conduct certain transactions without doing so.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b> Technologically Gathered. We collect some information
            automatically as you navigate the Site. For example, we collect traffic data (such as
            information about how much time visitors spend on the Site or each page, etc.), collect
            and log “clickstream data” (such as internet protocol addresses, pages visited, clicks
            made, and similar information) and information about your computer and internet
            connection (such as operating system and browser type) for all visitors. This sort of
            information is generally considered Non-personal Information (unless it is combined with
            Personal Information). We may combine information about your use with information about
            others’ use to generate aggregated Non-personal Information about visitors as a group.
            Some of the technology used to collect information is explained further in Trayvo’s
            Cookie Policy.
          </NormalText>

          <Heading className="inline">4. Cookies and Related Technology.</Heading>

          <NormalText classes="inline">
            Trayvo may use the following technologies to collect data as you visit the Site.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b> “Cookies” are small pieces of data that a website, when
            visited by a user, asks your browser to store on your device in order to remember
            information about you, such as your language preference or login information. Cookies
            can be either persistent or temporary. A persistent cookie retains user preferences for
            a particular website, allowing those preferences to be used in future sessions and
            remains valid until its set expiry date (unless deleted by the user before the expiry
            date). Temporary cookies expire at the end of a user session when the browser is closed.
          </NormalText>

          <NormalText classes="ml-4">
            Most internet browsers are initially set to accept cookies. Cookies are classified in
            the following categories: Necessary Cookies, Performance Cookies, Functional Cookies,
            and Targeting Cookies. You cannot opt-out of Necessary Cookies, but you can opt out of
            the other cookie categories by clicking on the “cookie settings” button in your browser,
            in this policy, or in the applicable website cookie notice. If you decline the use of
            cookies or set your browser to refuse cookies, then you may not be able to access or use
            portions of this Site.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b> “Web beacons” and “pixels” are small bits of code embedded
            in web pages or emails that assist in delivering cookies and measuring statistics. Web
            beacons and pixels help Trayvo determine whether a page on this Site has been viewed
            and, if so, how many times. Trayvo may also use web beacons and pixels in email messages
            to determine what the recipient has done with the message. Although you cannot decline
            to receive web beacons and pixels when visiting a web page, you can choose not to
            receive them by email if you disable HTML images or refuse HTML email messages.
          </NormalText>

          <Heading className="inline" id="section-5">
            5. How We Use the Information.
          </Heading>

          <NormalText classes="inline ml-1">
            Trayvo uses information that you provide and/or that Trayvo collects to present the Site
            to you, improve the Site, deliver a more personalized experience to you, to provide you
            with information, and/or services that you request from Trayvo, to market Trayvo’s
            services to you, to fulfill any other purpose for which you provide such information, to
            provide you with other information you requested, in any other way Trayvo may describe
            when you provide the information, and for any other purpose with your consent. The way
            Trayvo uses such information is described in more detail in the chart below. Trayvo may
            aggregate the information you provide or that Trayvo collects from you with information
            collected from other users to allow Trayvo to evaluate its services. Trayvo may use the
            information it collects to contact you about Trayvo’s services that may interest you; if
            required by law, we will obtain your approval to do so. If you do not want Trayvo to use
            your information this way, please see Section 7 below.
          </NormalText>

          <div className="table w-full mt-3">
            <div className="table-row-group">
              <div className="table-row">
                <div className="table-cell text-lg border p-4 font-bold bg-secondary">
                  Why Trayvo processes your information
                </div>
                <div className="table-cell text-lg text-lg border p-4 font-bold bg-secondary">
                  Legal basis for the processing purpose
                </div>
              </div>

              {privacyPolicyProcessInfoTableData.map(({ statement, purposes }) => (
                <div className="table-row" key={statement}>
                  <div className="table-cell text-lg border p-3 w-3/5">{statement}</div>

                  <div className="table-cell text-lg border p-3">
                    <ul className="list-circle list-inside">
                      {purposes.map(purpose => (
                        <li key={purpose}>{purpose}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <Heading id="section-6">6. To Whom We Disclose.</Heading>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b> <u>General</u>. Trayvo may disclose aggregated information
            about its users and information that does not identify any individual without
            restriction. Trayvo may disclose personal information that it collects or you provide as
            described in this Privacy Policy to Trayvo’s trainers, business partners, affiliates,
            contractors, or service providers, and other third parties Trayvo uses to support its
            business, including but not limited to customer support, web hosting, information
            technology, payment processing, product fulfillment, fraud control, legal services,
            marketing, and remarketing, advertising and analytics providers. Trayvo may also
            disclose personal information that it collects or you provide to a purchaser or
            successor in the event of a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of Trayvo’s assets; to fulfill the
            purpose for which you provided the personal information; for any other purpose when you
            consent to such disclosure at the time you provide the information. Trayvo may also
            disclose your personal information to comply with any court order, law, or legal
            process, including to respond to any government or regulatory request, to enforce
            Trayvo’s terms of use and other agreements, and if Trayvo believes disclosure is
            necessary or appropriate to protect the rights, property, or safety of Trayvo, its
            customers, or others.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b> <u>Disclosure for Third Party Direct Marketing</u>. Trayvo
            does not share personal information it receives or collects from you with its business
            partners for their direct marketing purposes (to market their own goods or services
            directly to you). Trayvo will not do so in the future unless you have first consented
            and then only unless or until you withdraw your consent.
          </NormalText>

          <Heading className="inline">7. Your Choices.</Heading>

          <NormalText classes="inline">
            You have some choices about how personal information you provide to us or that we
            collect is used.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b> <u>Do Not Track</u>. The Site does not respond to web
            browser Do Not Track (DNT) signals.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b> <u>Tracking Technologies</u>. You may set your browser to
            refuse some or all browser cookies, or to alert you when cookies are being sent. To
            learn how to manage or delete cookies on your browser, visit the browser developer’s
            website. To learn how to manage Flash cookie settings, visit the Flash player settings
            page on Adobe’s website. If you disable or refuse cookies, please note that parts of the
            Site may not be accessible or may not function properly.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(c)</b> <u>Updating Information</u>. You may contact Trayvo at the
            contact information set forth below to request access to, correct, or delete certain
            personal information that you have provided to Trayvo. Trayvo may not accommodate a
            request to change information if Trayvo believes the change would violate any law or
            legal requirement or cause the information to be incorrect.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(c)</b> <u>Updating Information</u>. You may contact Trayvo at the
            contact information set forth below to request access to, correct, or delete certain
            personal information that you have provided to Trayvo. Trayvo may not accommodate a
            request to change information if Trayvo believes the change would violate any law or
            legal requirement or cause the information to be incorrect.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(d)</b> <u>California Residents</u>. You may see the Supplemental
            Notice applicable to California residents{' '}
            <a href="/california-residents-notice" className="text-primary">
              here
            </a>
            .
          </NormalText>

          <Heading className="inline">8. For Residents of EEA.</Heading>

          <NormalText classes="inline ml-1">
            If you reside in the European Economic Area (the “EEA”), the following provisions apply
            to you:
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b> <u>Access</u>. You may request access to your personal
            information and the following information regarding our use of it: (i) purpose of the
            use, (ii) categories of personal information used, and (iii) to whom we have disclosed
            your personal information.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b> <u>Correction</u>. You may request that Trayvo correct any
            inaccurate personal information we have.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(c)</b> <u>Right to Be Forgotten</u>. You may request that Trayvo
            erase your personal information when Trayvo no longer needs such data.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(d)</b> <u>Withdraw Consent</u>. If Trayvo’s ability to use your
            personal information depends on consent you have given, you may withdraw such consent.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(e)</b> <u>Restriction</u>. You may request that Trayvo restrict (or
            suspend) use of your personal information.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(f)</b> <u>Object</u>. You may object to Trayvo’s use of your
            personal information to perform services you have not requested.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(g)</b> <u>Copies and Portability</u>. You may request copies of
            your personal information that Trayvo possesses in a structured, commonly used, and
            machine readable format and you may request that this be transmitted to another
            controller.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(h)</b> <u>Complaint</u>. You may lodge a complaint with the
            supervising authority of your country.
          </NormalText>

          <Heading className="inline">9. Data Transfer</Heading>

          <NormalText classes="incline ml-1">
            Trayvo stores your personal information in the United States. The privacy protections in
            the United States may not be the same as in your home country. If you are located in a
            country outside the United States and submit personal information to Trayvo, you consent
            to the general use and disclosure of such information as provided in this Privacy Policy
            and to the transfer and/or storage of that information to the United States.
          </NormalText>

          <Heading>10. For Residents of Canada.</Heading>

          <NormalText classes="ml-4">
            <b className="mr-2">(g)</b>{' '}
            <u>Types of Personal Information Collected, Used, and Disclosed</u>. Trayvo collects
            various types of personal information directly from you, as well as from third parties
            and automatically through the use of analytics tools, as described in Section 2 above.
            The following chart identifies the categories of personal information collected by
            Trayvo, the source of such personal information, how Trayvo uses such personal
            information, and to whom Trayvo discloses such personal information:
          </NormalText>

          <div className="mt-3 overflow-x-scroll w-full">
            <div className="table">
              <div className="table-row-group">
                <div className="table-row">
                  <div className="table-cell text-lg border p-4 font-bold bg-secondary">
                    Category of Personal Information
                  </div>

                  <div className="table-cell text-lg text-lg border p-4 font-bold bg-secondary">
                    Source
                  </div>

                  <div className="table-cell text-lg text-lg border p-4 font-bold bg-secondary">
                    Business Purpose
                  </div>

                  <div className="table-cell text-lg text-lg border p-4 font-bold bg-secondary">
                    To Whom Disclosed (categories)
                  </div>
                </div>

                {privacyPolicyPersonalInfoDiscloseTableData.map(
                  ({ statement, sources, businessPurposes, toWhomDisclosedList }) => (
                    <div className="table-row" key={statement}>
                      <div className="table-cell text-lg border p-3 w-1/4">{statement}</div>

                      <div className="table-cell text-lg border p-3 w-1/4">
                        <ul className="list-line">
                          {sources.map(source => (
                            <li key={source}>{source}</li>
                          ))}
                        </ul>
                      </div>

                      <div className="table-cell text-lg border p-3 w-1/4">
                        <ul className="list-line">
                          {businessPurposes.map(businessPurpose => (
                            <li key={businessPurpose}>{businessPurpose}</li>
                          ))}
                        </ul>
                      </div>

                      <div className="table-cell text-lg border p-3 w-1/4">
                        <ul className="list-line">
                          {toWhomDisclosedList.map(toWhomDisclosed => (
                            <li key={toWhomDisclosed}>{toWhomDisclosed}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b>
            <u>Risk of Harm and Other Consequences</u>. You choose whether to provide Personal
            Information to Trayvo and whether to allow Trayvo to collect Personal Information about
            you. You may not be able to use certain portions or features of the Site or conduct
            certain transactions without doing so. Trayvo has implemented some measures designed to
            secure your personal information from accidental loss and from unauthorized access, use,
            alteration, and disclosure. Unfortunately, however, the transmission of information via
            the internet is not completely secure. Trayvo cannot guarantee the security of your
            personal information transmitted to or through the Site, and there is a risk that your
            personal information will be compromised.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(c)</b>
            <u>Methods of Collection and Obtaining Consent</u>. We may process your Personal
            Information in accordance with your consent or other instructions. We share Personal
            Information with third parties when we have your consent to do so. For example, if you
            decide to register an account, complete a transaction, or use our Site, you consent to
            our privacy policy and the use of your Personal Information as disclosed herein. We may
            also share your Personal Information with third parties, including our service
            providers.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(d)</b>
            <u>Choices and Withdrawal of Consent.</u>. We may process your Personal Information in
            accordance with your consent or other instructions. We share Personal Information with
            third parties when we have your consent to do so. For example, if you decide to register
            an account, complete a transaction, or use our Site, you consent to our privacy policy
            and the use of your Personal Information as disclosed herein. We may also share your
            Personal Information with third parties, including our service providers.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(e)</b>
            <u>Account Closing and Deletion of Information.</u>. Subject to certain exceptions, you
            may request that Trayvo close your account and delete Personal Information about you
            that it has collected and retained.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(f)</b>
            <u>Use of Third Parties</u>. As described in this Privacy Policy, Trayvo relies on its
            business partners, affiliates, contractors, distributors, or service providers as well
            as to other third parties to support its business and may receive Personal Information
            from or disclose information to these third parties in the course of receiving support
            from these third parties. More information about Trayvo’s use of third parties is
            described above in <i>Section 10(c)</i>.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(g)</b>
            <u>Disclosure of Data for Third Party Use</u>. Other than as disclosed herein, Trayvo
            does not disclose or sell Personal Information for third party use.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(h)</b>
            <u>Cross-Border Transfers</u>. For the reasons and purposes set forth in this Privacy
            Policy, the Personal Information and other data that Trayvo collects may be transferred
            to and stored or otherwise processed in the United States or other locations. While in
            another jurisdiction for processing, your Personal Information may be accessed by the
            courts, law enforcement, and national security authorities of that jurisdiction. These
            jurisdictions may not provide the same level of data protection as your home
            jurisdiction.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(i)</b>
            <u>Law Enforcement Requests</u>. We disclose Personal Information when we believe doing
            so is reasonably necessary to comply with applicable law or legal process, including an
            enforceable request from law enforcement, to respond to claims, to enforce or apply our
            Terms of Use, or to protect the rights, property, or personal safety of Trayvo, our
            users, employees, or others.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(j)</b>
            <u>Access and Correction Requests</u>. Subject to certain exceptions, you have the right
            to request certain information about Trayvo’s collection, use, and/or disclosure of your
            personal information. After receipt of an access request from you, Trayvo will disclose
            to you the categories of personal information collected, categories of sources for the
            categories of personal information collected, business or commercial purpose for
            collecting such personal information, categories of third parties with whom Trayvo
            shared such personal information, and specific pieces of personal information collected
            about you. If any of the information Trayvo maintains about you is incorrect, you may
            also request that Trayvo correct such information about you.
          </NormalText>

          <Heading>11. Data Retention and Security.</Heading>

          <NormalText classes="ml-4">
            <b className="mr-2">(a)</b>
            <u>Data Retention</u>. Trayvo retains personal data in accordance with applicable law.
            Trayvo may store your personal information to the extent required to comply with
            Trayvo’s legal, accounting, and/or reporting requirements. Additionally, Trayvo may
            continue to store your personal information contained in standard backups. Upon your
            request, Trayvo will delete or de-identify your personal information, unless Trayvo is
            legally required or permitted to maintain such personal information.
          </NormalText>

          <NormalText classes="ml-4">
            <b className="mr-2">(b)</b>
            <u>Data Security</u>. Trayvo has implemented some measures designed to secure your
            personal information from accidental loss and from unauthorized access, use, alteration,
            and disclosure. Unfortunately, the transmission of information via the internet is not
            completely secure. Trayvo cannot guarantee the security of your personal information
            transmitted to or through the Site. Any transmission of personal information is at your
            own risk. Trayvo is not responsible for circumvention of any privacy settings or
            security measures contained in the Site.
          </NormalText>

          <Heading className="inline">12. Changes to Privacy Policy.</Heading>

          <NormalText classes="inline ml-1">
            Changes to the Privacy Policy will be posted on this page. If Trayvo makes material
            changes to the way it treats users personal information, Trayvo may choose notify you by
            email or through a notice in addition to posting. The date the Privacy Policy was last
            revised appears at the top of the Privacy Policy. You are responsible for ensuring
            Trayvo has a current email address for you and for periodically visiting the Site and
            the Privacy Policy to check for any changes.
          </NormalText>

          <br />
          <br />
          <Heading className="inline">13. Children.</Heading>

          <NormalText classes="inline ml-1">
            The Site is not for children. Trayvo does not knowingly collect personal information
            from children under the age of 13 through the Site. If Trayvo learns that it has
            collected or received such information, it will delete that information. If you believe
            we might have information from or about a child under the age of 13, please let us know
            by contacting us using the information set forth in Section 15.
          </NormalText>

          <br />
          <br />
          <Heading className="inline">14. Nevada Residents.</Heading>

          <NormalText classes="inline">
            Under Nevada law, Nevada residents may submit a request directing Trayvo not to make
            certain disclosures of personal information we maintain about them. If you are a Nevada
            resident and wish to exercise this right, you may contact us by email at
            support@trayvo.com or by phone at +1 (860) 378-5653.
          </NormalText>

          <br />
          <br />
          <Heading className="inline">15. Links.</Heading>

          <NormalText classes="inline ml-1">
            This Site contains links to third party websites that Trayvo does not control. Trayvo
            cannot be held responsible for third parties’ privacy practices or content provided on
            such websites. If you click on one of these links, please understand that you are
            leaving the Site and any information you provide will not be covered by this Privacy
            Policy. Please read that website’s privacy policy before providing any information.
          </NormalText>

          <br />
          <br />
          <Heading className="inline">16. Contact Information.</Heading>

          <NormalText classes="inline ml-1">
            If you have any questions or comments about this Privacy Policy and Trayvo’s privacy
            practices, please contact Trayvo at:
          </NormalText>

          <NormalText classes="mt-3 mb-1">Trayvo Holdings, LLC</NormalText>
          <NormalText classes="mb-1">Attn: Customer Service</NormalText>
          <NormalText classes="mb-1">Phone: +1 (860) 378-5653</NormalText>
          <NormalText classes="mb-1">Email: support@trayvo.com</NormalText>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
