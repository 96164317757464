import { combineReducers } from 'redux';
import { Map } from 'immutable';

const calendarDefault = {
  availabilities: [],
  availabilityPagy: null,
  slotsByDate: [],
  entries: [],
  entry: null,
  schedule: [],
  schedulePagy: null,
  entriesCount: null,
  trainingAvailabilities: [],
  selectedTraining: null,
};

const calendarReducer = (state = Map(calendarDefault), action) => {
  switch (action.type) {
    case 'SET_AVAILABILITIES':
      return state.set('availabilities', action.payload.data);

    case 'SET_AVAILABILITY_PAGY':
      return state.set('availabilityPagy', action.payload.data);

    case 'SET_ENTRIES':
      return state.set('entries', action.payload.data);

    case 'SET_ENTRIES_BY_DATE':
      return state.set('slotsByDate', action.payload.data);

    case 'SET_ENTRY':
      return state.set('entry', action.payload.data);

    case 'SET_SCHEDULE':
      return state.set('schedule', action.payload.data);

    case 'SET_SCHEDULE_PAGY':
      return state.set('schedulePagy', action.payload.data);

    case 'SET_CALENDAR_ENTRIES_COUNT':
      return state.set('entriesCount', action.payload.data);

    case 'SET_TRAINING_AVAILABILITIES':
      return state.set('trainingAvailabilities', action.payload.data);

    case 'SET_SELECTED_TRAINING':
      return state.set('selectedTraining', action.payload.data);

    default:
      return state;
  }
};

export default combineReducers({
  calendar: calendarReducer,
});
