import React, { Component, useState } from 'react';
import DatePicker from 'react-date-picker';
import { InputLabel } from './index';
import moment from 'moment';

function CustomDatePicker({ title, selected, handleOnChange }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col">
      <InputLabel label={title} />

      <DatePicker
        className="font-sf-regular text-xs rounded form-control"
        calendarClassName=" border-none x-auto"
        placeholderText="Start Date"
        format="MM-dd-yyyy"
        showMonthDropdown
        calendarIcon={null}
        clearIcon={null}
        scrollableYearDropdown
        scrollableMonthYearDropdown
        value={selected}
        minDate={new Date()}
        maxDate={new Date(moment().add(1, 'Y'))}
        showYearDropdown
        required
        yearDropdownItemNumber={100}
        onChange={handleOnChange}
        onClick={() => setOpen(true)}
        onCalendarClose={() => setOpen(false)}
        isOpen={open}
      />
    </div>
  );
}

export default CustomDatePicker;
