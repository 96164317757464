import React from 'react';
import { TrainingBadge } from './index';

function ColorIndication() {
  const trainingTypes = ['individual', 'group', 'virtual'];

  return (
    <div className="flex flex-col content-start absolute top-0 p-3" style={{ right: '40px' }}>
      {trainingTypes.map((trainingType, index) => (
        <div key={index}>
          <TrainingBadge trainingType={trainingType} large={true} />
        </div>
      ))}
    </div>
  );
}
export default ColorIndication;
