export default {
  //=============================Athlete=============================
  signUp: () => `/athlete/profiles`,
  signIn: () => `/athlete/sessions`,
  viewProfile: () => `/athlete/profiles`,

  //bookings
  bookings: () => `/athlete/bookings`,

  //bookingRequests
  requests: () => `/athlete/booking_requests`,

  //availabilities
  availabilities: () => `/athlete/availabilities`,
  entries: () => `/athlete/calendar_entries`,
  calendarEntriesCount: () => '/athlete/calendar_entries/calendar_entries_count',
  updateEntry: () => `/athlete/calendar_entries`,
  trainingAvailabilities: () => `/athlete/availabilities/provided_service_availabilities`,

  //connect-account
  account: () => `/athlete/profiles/connect_account`,
  payments: () => `athlete/payments`,
  transferCharge: id => `/payments/${id}/transfer_charge/`,

  //services
  provided: () => `/athlete/provided_services`,
  trainingSignedUrl: () => `/athlete/provided_services/signed_url?file_type=jpg`,
  service: () => `/athlete/services`,

  profileSignedUrl: () => `/athlete/profiles/signed_url?file_type=jpg`,
  videoSignedUrl: extension => `/athlete/profiles/signed_url?file_type=${extension}`,

  //check if email or phone is valid
  checkValid: () => '/athlete/profiles/check_email_or_phone',

  //resetpassword
  sendResetPassword: () => `/athlete/profiles/send_reset_password`,
  updatepassword: () => `/athlete/profiles/update_password`,
  resetPassword: () => `/athlete/profiles/reset_password`,

  getSchools: () => `/high_schools`,
  getColleges: () => `/colleges`,
  getSports: () => `/sports`,

  //referral code validation
  referralCode: code => `/athlete/profiles/check_valid_referral_code?referral_code=${code}`,
};
