import React from 'react';
import Loader from 'react-loader-spinner';

function LoaderIndicator(props) {
  return (
    <Loader
      visible={true}
      type="ThreeDots"
      color={props.color ? props.color : '#042e60'}
      height={props.small ? 20 : 80}
      width={props.small ? 40 : 80}
    />
  );
}

export default LoaderIndicator;
