export const athleteTypes = [
  { label: 'Coach', value: 'coach' },
  { label: 'Current College Athlete', value: 'current_college_athlete' },
  { label: 'D1 College Coach', value: 'd1_college_coach' },
  { label: 'D2 College Coach', value: 'd2_college_coach' },
  { label: 'D3 College Coach', value: 'd3_college_coach' },
  { label: 'JuCo Coach', value: 'juco_coach' },
  { label: 'NAIA Coach', value: 'naia_coach' },
  { label: 'Trainer', value: 'trainer' },
];

export const trainingType = [
  { label: 'Individual', value: 2 },
  { label: 'Group', value: 3 },
  { label: 'Virtual', value: 1 },
];

export const privacySettings = [
  { label: 'Public (Anyone can book this training option)', value: 1 },
  { label: 'Private (Only people you choose can book this training option)', value: 2 },
];

export const days = [
  { day: 'Mon', checked: false, value: 1 },
  { day: 'Tues', checked: false, value: 2 },
  { day: 'Wed', checked: false, value: 3 },
  { day: 'Thurs', checked: false, value: 4 },
  { day: 'Fri', checked: false, value: 5 },
  { day: 'Sat', checked: false, value: 6 },
  { day: 'Sun', checked: false, value: 0 },
];

//user choice during availability creation
export const userChoice = {
  ONETIME: 'One Time',
  REPEAT: 'Multiple',
};

//training type during availability creation
export const trainingOption = {
  VIRTUAL: 'Virtual',
  INDIVIDUAL: 'Individual',
  GROUP: 'Group',
};

//Separate colors for 3 types of training's entries on calendar to render
export const entryColor = {
  VIRTUALCOLOR: '#149617',
  GROUPCOLOR: '#FD8924',
  INDIVIDUALCOLOR: '#3B86FC',
};

const getClasses = () => {
  let arr = [];
  let i = 1970;
  for (; i < 2029; i++) {
    arr.push({ label: i, value: i });
  }
  return arr;
};

export const passingYear = getClasses();

export const availableSlots = [...Array(101).keys()].reduce((arr, v) => {
  if (v > 1) arr.push({ label: v.toString(), value: v })
  return arr
}, [])

export const states = [
  { label: 'AK', value: 'Alabama' },
  { label: 'AL', value: 'Alaska' },
  { label: 'AR', value: 'Arizona' },
  { label: 'AZ', value: 'Arkansas' },
  { label: 'CA', value: 'California' },
  { label: 'CO', value: 'Colorado' },
  { label: 'CT', value: 'Connecticut' },
  { label: 'DE', value: 'Delaware' },
  { label: 'FL', value: 'Florida' },
  { label: 'GA', value: 'Georgia' },
  { label: 'HI', value: 'Hawaii' },
  { label: 'IA', value: 'Idaho' },
  { label: 'ID', value: 'Illinois' },
  { label: 'IL', value: 'Indiana' },
  { label: 'IN', value: 'Iowa' },
  { label: 'KS', value: 'Kansas' },
  { label: 'KY', value: 'Kentucky' },
  { label: 'LA', value: 'Louisiana' },
  { label: 'MA', value: 'Maine' },
  { label: 'MD', value: 'Maryland' },
  { label: 'ME', value: 'Massachusetts' },
  { label: 'MI', value: 'Michigan' },
  { label: 'MN', value: 'Minnesota' },
  { label: 'MO', value: 'Mississippi' },
  { label: 'MS', value: 'Missouri' },
  { label: 'MT', value: 'Montana' },
  { label: 'NC', value: 'Nebraska' },
  { label: 'ND', value: 'Nevada' },
  { label: 'NE', value: 'New Hampshire' },
  { label: 'NH', value: 'New Jersey' },
  { label: 'NJ', value: 'New Mexico' },
  { label: 'NM', value: 'New York' },
  { label: 'NV', value: 'North Carolina' },
  { label: 'NY', value: 'North Dakota' },
  { label: 'OH', value: 'Ohio' },
  { label: 'OK', value: 'Oklahoma' },
  { label: 'OR', value: 'Oregon' },
  { label: 'PA', value: 'Pennsylvania' },
  { label: 'RI', value: 'Rhode Island' },
  { label: 'SC', value: 'South Carolina' },
  { label: 'SD', value: 'South Dakota' },
  { label: 'TN', value: 'Tennessee' },
  { label: 'TX', value: 'Texas' },
  { label: 'UT', value: 'Utah' },
  { label: 'VA', value: 'Vermont' },
  { label: 'VT', value: 'Virginia' },
  { label: 'WA', value: 'Washington' },
  { label: 'WI', value: 'West Virginia' },
  { label: 'WV', value: 'Wisconsin' },
  { label: 'WY', value: 'Wyoming' },
];

export const accentColor = 'linear-gradient(to right, #1c8f9e, #5cb7b7)';

export const removeArrowInSelect = {
  dropdownIndicator: defaultStyles => ({
    ...defaultStyles,
    '& svg': { display: 'none' },
  }),
};

export const customStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: '#F5F6FA',
  }),
};

export const bookingStatus = {
  INCOMPLETE: 'incomplete',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  REJECTED: 'rejected',
};

export const paymentStatus = {
  PAID: 'paid',
  ACCEPTED: 'accepted',
};

export const statusColor = {
  UPCOMING: '#ffa500',
  CONFIRM: '#e50000',
  COMPLETED: '#32CD32',
  CANCELED: '#989898',
};

export const privacyPolicyProcessInfoTableData = [
  {
    statement:
      'To respond to your request or to fulfill the purpose for which you provide the information',
    purposes: ['Performance of a contract', 'Your consent'],
  },
  {
    statement: 'To personalize and improve your experience with the Site',
    purposes: ['Legitimate interest'],
  },
  {
    statement: 'To ensure technical functionality and proper operation of the Site',
    purposes: ['Legitimate interest'],
  },
  {
    statement: 'To improve our services or to develop new services',
    purposes: ['Legitimate interest'],
  },
  {
    statement:
      'To communicate with you for product or services-related purposes, such as regarding support issues and customer service inquiries',
    purposes: ['Performance of a contract', 'Legitimate interest', 'Consent'],
  },
  {
    statement:
      'To communicate with you for the following purposes: to respond to a request, marketing, remarketing, research, or other promotional purposes, via email, notifications or other messages, consistent with any permissions you may have communicated to us',
    purposes: ['Consent', 'Legitimate interest'],
  },
  {
    statement: 'To comply with our legal obligations',
    purposes: ['Legitimate interest'],
  },
  {
    statement: 'To carry out our obligations and enforce our rights',
    purposes: ['Legitimate interest', 'Contract'],
  },
  {
    statement: 'In any other way described when the information is collected',
    purposes: ['Legitimate interest', 'Consent'],
  },
];

export const privacyPolicyPersonalInfoDiscloseTableData = [
  {
    statement:
      'Identifiers: Name, postal address, IP address, email address, driver’s license number, telephone number',
    sources: ['you', 'technologically gathered from you', 'service providers', 'business partners'],
    businessPurposes: [
      'facilitate transactions',
      'marketing/ communications',
      'fulfill requests',
      'provide support',
      'provide services',
    ],
    toWhomDisclosedList: [
      'service providers',
      'business partners',
      'data analytics providers',
      'advertising and remarketing partners',
      'payment processors',
      'customer support partners',
      'internet service providers',
    ],
  },
  {
    statement:
      'Name, address, bank account number, credit card number, debit card number, or any other financial information',
    sources: ['you', 'service providers', 'business partners'],
    businessPurposes: [
      'facilitate transactions',
      'marketing/ communications',
      'fulfill requests',
      'provide support',
      'respond to inquiries',
    ],
    toWhomDisclosedList: [
      'service providers',
      'business partners',
      'data analytics providers',
      'advertising and remarketing partners',
      'payment processors',
      'customer support partners',
      'internet service providers',
    ],
  },
  {
    statement: 'Purchasing or consuming histories, tendencies, or preferences',
    sources: ['you', 'service providers', 'business partners'],
    businessPurposes: [
      'facilitate transactions',
      'provide services to customers',
      'improve services',
      'fulfill contractual obligations',
      'respond to inquiries',
    ],
    toWhomDisclosedList: [
      'service providers',
      'business partners',
      'data analytics providers',
      'advertising and remarketing partners',
      'payment processors',
      'customer support partners',
      'internet service providers',
    ],
  },
  {
    statement: 'Internet/network activity',
    sources: ['you/your device', 'service providers'],
    businessPurposes: [
      'improve Site',
      'personalize Site',
      'improve Site experience',
      'marketing and remarketing',
    ],
    toWhomDisclosedList: [
      'service providers',
      'business partners',
      'data analytics providers',
      'advertising and remarketing partners',
      'payment processors',
      'customer support partners',
      'internet service providers',
    ],
  },
  {
    statement: 'Geolocation data',
    sources: ['you/your device', 'service providers'],
    businessPurposes: [
      'improve Site',
      'personalize Site',
      'improve Site experience',
      'improve customer experience',
      'marketing and remarketing',
    ],
    toWhomDisclosedList: [
      'service providers',
      'business partners',
      'data analytics providers',
      'advertising and remarketing partners',
      'payment processors',
      'customer support partners',
      'internet service providers',
    ],
  },
  {
    statement: 'Inferences drawn from other personal information',
    sources: ['business partners', 'service providers'],
    businessPurposes: [
      'provide services',
      'marketing and remarketing',
      'improve customer experience',
      'improve service offerings',
      'respond to inquiries',
    ],
    toWhomDisclosedList: [
      'service providers',
      'business partners',
      'data analytics providers',
      'advertising and remarketing partners',
      'payment processors',
      'customer support partners',
      'internet service providers',
    ],
  },
];

export const CaliforniaResidentsNoticeTableData = [
  {
    statement: 'Identifiers',
    sources: ['you', 'technologically gathered from you', 'service provider', 'business partners'],
    businessPurposes: [
      'facilitate transactions',
      'marketing/ communications',
      'fulfill requests',
      'provide support',
      'provide services',
      'fulfill contractual obligations',
    ],
    toWhomDisclosedList: ['service providers', 'business partners'],
  },
  {
    statement: 'CA Customer Records Statute categories',
    sources: ['you', 'service provider', 'business partners'],
    businessPurposes: [
      'facilitate transactions',
      'marketing/ communications',
      'fulfill requests',
      'provide support',
      'provide services',
      'fulfill contractual obligations',
      'respond to inquiries',
    ],
    toWhomDisclosedList: [
      'service providers',
      'business partners',
      'analytics and advertising partners',
    ],
  },
  {
    statement: 'Protected Class Characteristics',
    sources: ['n/a'],
    businessPurposes: ['n/a'],
    toWhomDisclosedList: ['n/a'],
  },
  {
    statement: 'Commercial Information',
    sources: ['you', 'service provider', 'business partners'],
    businessPurposes: [
      'facilitate transactions',
      'provide services to customers',
      'improve services',
    ],
    toWhomDisclosedList: ['service providers', 'business partners'],
  },
  {
    statement: 'Biometric Information',
    sources: ['n/a'],
    businessPurposes: ['n/a'],
    toWhomDisclosedList: ['n/a'],
  },
  {
    statement: 'Internet/network activity',
    sources: ['you/your device', 'service providers'],
    businessPurposes: [
      'improve Site',
      'personalize Site',
      'improve Site experience',
      'marketing and remarketing',
    ],
    toWhomDisclosedList: [
      'service providers',
      'business partners',
      'advertising and remarketing partners',
    ],
  },
  {
    statement: 'Geolocation data',
    sources: ['you/your device', 'service providers'],
    businessPurposes: [
      'improve Site',
      'personalize Site',
      'improve Site experience',
      'improve customer experience',
      'marketing and remarketing',
    ],
    toWhomDisclosedList: ['analytics and advertising partners', 'business partners'],
  },
  {
    statement: 'Sensory data',
    sources: ['n/a'],
    businessPurposes: ['n/a'],
    toWhomDisclosedList: ['n/a'],
  },
  {
    statement: 'Professional/employment-related information',
    sources: ['you'],
    businessPurposes: [
      'facilitate transactions',
      'provide services to customers',
      'improve services',
      'fulfill contractual obligations',
      'respond to inquiries',
    ],
    toWhomDisclosedList: ['analytics and advertising partners', 'business partners'],
  },
  {
    statement: 'Non-public education information',
    sources: ['n/a'],
    businessPurposes: ['n/a'],
    toWhomDisclosedList: ['n/a'],
  },
  {
    statement: 'Inferences drawn from other personal information',
    sources: ['analytics and advertising partners', 'business partners', 'service providers'],
    businessPurposes: [
      'provide services',
      'marketing and remarketing',
      'improve customer experience',
      'improve service offerings',
      'respond to inquiries',
    ],
    toWhomDisclosedList: ['analytics and advertising partners', 'business partners'],
  },
];
