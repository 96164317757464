import api from '../api';
import qs from 'qs';
import { client } from '../index';
import { notify } from '../../utils/utilities';
import { showHUD, hideHUD } from '../User/actions';
import moment from 'moment';

export const getAvailabilities =
  (page = 1) =>
  async dispatch => {
    let payload = {
      page,
      per_page: 10,
    };

    dispatch(showHUD());
    let paramsString = qs.stringify(payload);
    const { data } = await client().get(`${api.availabilities()}?${paramsString}`);
    dispatch({ type: 'SET_AVAILABILITIES', payload: { data: data.data } });
    dispatch({ type: 'SET_AVAILABILITY_PAGY', payload: { data: data.pagy } });
    dispatch(hideHUD());
  };

export const getAvailability = id => async dispatch => {
  dispatch(showHUD());
  const { data } = await client().get(`${api.availabilities()}/${id}`);
  dispatch(hideHUD());
  return data;
};

export const getCalendarEntries = month => async dispatch => {
  let payload = {
    q: { between: month },
    page: 1,
    per_page: 1000,
  };

  let paramsString = qs.stringify(payload);
  dispatch(getcalendarEntriesCount(month));
  const { data } = await client().get(`${api.entries()}?${paramsString}`);
  dispatch({ type: 'SET_ENTRIES', payload: { data: data.data ? data.data : [] } });
};

export const getCalendarEntriesByDate = date => async dispatch => {
  let payload = {
    q: { booking_date_eq: date },
  };

  let paramsString = qs.stringify(payload);
  const { data } = await client().get(`${api.entries()}?${paramsString}`);
  dispatch({ type: 'SET_ENTRIES_BY_DATE', payload: { data: data.data ? data.data : [] } });
};

export const getEntry = id => async dispatch => {
  dispatch(showHUD());
  const { data } = await client().get(`${api.entries()}/${id}`);
  dispatch({ type: 'SET_ENTRY', payload: { data: data.data ? data.data : null } });
  dispatch(hideHUD());
};

export const createAvailability = payload => async dispatch => {
  let { data } = await client().post(api.availabilities(), payload);
  return data;
};

export const deleteEvent = id => async dispatch => {
  dispatch(showHUD());
  let data = await client().delete(`${api.availabilities()}/${id}`);
  dispatch(hideHUD());
  if (data?.data?.remove) notify('Deleted successfully', 'success');
  dispatch(getAvailabilities());
  dispatch(getCalendarEntries());
  dispatch(getSchedule());
  return data;
};

export const updateAvailability = (payload, id) => async dispatch => {
  await client().put(`${api.availabilities()}/${id}`, payload);
  notify('Updated successfully', 'success');
};

export const deleteEntry = id => async dispatch => {
  await client().delete(`${api.entries()}/${id}`);
  dispatch(getSchedule());
  notify('Deleted successfully', 'success');
};

export const updateEntry = (payload, id) => async dispatch => {
  await client().put(`${api.updateEntry()}/${id}`, payload);
  notify('Updated successfully', 'success');
};

export const getSchedule =
  (page = 1) =>
  async (dispatch, getState) => {
    const currentUser = getState().User.current.get('currentUser');

    let payload = {
      page,
      per_page: 10,
      q: { upcoming: currentUser.attributes.time_zone },
    };
    let paramsString = qs.stringify(payload);
    dispatch(showHUD());
    let { data } = await client().get(`${api.entries()}?${paramsString}`);
    dispatch({ type: 'SET_SCHEDULE', payload: { data: data.data } });
    dispatch({ type: 'SET_SCHEDULE_PAGY', payload: { data: data.pagy } });
    dispatch(hideHUD());
  };

export const getcalendarEntriesCount = month => async dispatch => {
  let { data } = await client().get(
    `${api.calendarEntriesCount()}?month=${month ? month : moment().format('YYYY-MM-DD')}`
  );
  dispatch({ type: 'SET_CALENDAR_ENTRIES_COUNT', payload: { data: data.response } });
};

export const getTrainingAvailabilities = id => async dispatch => {
  let { data } = await client().get(`${api.trainingAvailabilities()}?id=${id}`);
  dispatch({ type: 'SET_TRAINING_AVAILABILITIES', payload: { data: data.data } });
};

export const setSelectedTraining = selectedTraining => async dispatch => {
  dispatch({ type: 'SET_SELECTED_TRAINING', payload: { data: selectedTraining } });
};
