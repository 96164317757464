import React, { Fragment } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import Async from 'react-select/async';
import { athleteTypes, removeArrowInSelect, customStyles } from '../../../constants';
import { InputLabel, OnboardingAction, GooglePlacesInput } from '../../Shared';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';

function TrainerInfo({
  handleOnChange,
  sportId,
  school,
  college,
  getSchools,
  getColleges,
  sports,
  type,
  club,
  currentLocation,
  setCurrentLocation,
  setCurrentLocationData,
  hometownLocation,
  setHomeTownLocation,
  setHomeTownLocationData,
  history,
  about,
  jerseyNumber,
  positionFirst,
  positionSecond,
  positionThird,
  getSportPositions,
  sportPositions,
  resetPositions,
  timeZone,
}) {
  return (
    <Fragment>
      <div className="text-left mb-2">
        <small style={{ fontSize: 15 }} className="text-black font-sf-semibold">
          Teams
        </small>
      </div>

      <div className="container-wrap">
        <div className="container-half md:pr-1">
          <div className="text-left">
            <InputLabel label="High School" color="black" />

            <Async
              components={{
                IndicatorSeparator: () => null,
              }}
              className="primary-text-regular"
              placeholder="Enter High School"
              value={school}
              loadOptions={getSchools.bind(this)}
              isSearchable
              onChange={selectedOption => handleOnChange('school', selectedOption)}
              styles={removeArrowInSelect}
            />
          </div>
        </div>

        <div className="container-half md:mt-0 margin-top-mob">
          <div className="text-left ">
            <InputLabel label="College" color="black" />

            <Async
              components={{
                IndicatorSeparator: () => null,
              }}
              className="primary-text-regular"
              placeholder="Enter College"
              value={college}
              loadOptions={getColleges.bind(this)}
              isSearchable
              onChange={selectedOption => handleOnChange('college', selectedOption)}
              styles={removeArrowInSelect}
            />
          </div>
        </div>
      </div>

      <div className="w-full text-left margin-top-mob">
        <InputLabel label="Club" color="black" />

        <Form.Control
          type="text"
          className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs"
          onChange={e => handleOnChange('club', e.target.value)}
          value={club}
          placeholder="Enter Club"
        />
      </div>

      <div className="text-left my-2 mt-3">
        <small style={{ fontSize: 15 }} className="text-black font-sf-semibold">
          Details
        </small>
      </div>

      <div className="flex flex-wrap justify-between">
        <div className="w-full md:w-2/5 text-left">
          <InputLabel label="Sport" color="black" />

          <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            className="primary-text-regular"
            placeholder="Select sport"
            value={sportId}
            isSearchable
            onChange={selectedOption => {
              resetPositions();
              handleOnChange('sportId', selectedOption);
              getSportPositions(selectedOption.value);
            }}
            options={sports}
            styles={customStyles}
          />
        </div>

        <div className="w-full md:w-2/5 text-left md:px-1 margin-top-mob">
          <InputLabel label="Type" color="black" />

          <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            className="primary-text-regular"
            placeholder="Select type"
            value={type}
            isSearchable={false}
            onChange={selectedOption => handleOnChange('type', selectedOption)}
            options={athleteTypes}
            styles={customStyles}
          />
        </div>

        <div className="w-full md:w-1/5 text-left margin-top-mob">
          <InputLabel label="Jersey Number" color="black" />

          <Form.Control
            type="number"
            className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs"
            onChange={e => handleOnChange('jerseyNumber', e.target.value)}
            value={jerseyNumber}
          />
        </div>
      </div>

      <div className="margin-top-mob" />

      <InputLabel label="Position" color="black" />
      <div className="flex justify-between position-col-for-mobile">
        <div className={`w-1/2 w-full-mobile ${sportId === null && 'cursor-not-allowed'}`}>
          <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            maxMenuHeight={180}
            className="primary-text-regular"
            isSearchable={false}
            value={positionFirst}
            isDisabled={sportId === null}
            styles={customStyles}
            placeholder="Position"
            onChange={selectedOption => handleOnChange('positionFirst', selectedOption)}
            options={sportPositions}
          />
        </div>

        <div className={`w-1/2 w-full-mobile md:px-1 ${sportId === null && 'cursor-not-allowed'}`}>
          <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            maxMenuHeight={180}
            className="primary-text-regular"
            isSearchable={false}
            value={positionSecond}
            isDisabled={sportId === null}
            styles={customStyles}
            placeholder="Position 2"
            onChange={selectedOption => handleOnChange('positionSecond', selectedOption)}
            options={sportPositions}
          />
        </div>

        <div className={`w-1/2 w-full-mobile ${sportId === null && 'cursor-not-allowed'}`}>
          <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            maxMenuHeight={180}
            className="primary-text-regular"
            isSearchable={false}
            value={positionThird}
            isDisabled={sportId === null}
            styles={customStyles}
            placeholder="Position 3"
            onChange={selectedOption => handleOnChange('positionThird', selectedOption)}
            options={sportPositions}
          />
        </div>
      </div>

      <div className="text-left my-2 mt-3">
        <small style={{ fontSize: 15 }} className="medium-black">
          Current City
        </small>
      </div>

      <div className="w-full margin-top-mob">
        <GooglePlacesInput
          value={currentLocation}
          setValue={val => setCurrentLocation(val)}
          setData={val => setCurrentLocationData(val)}
        />
      </div>

      <div className="text-left my-2 mt-3">
        <small style={{ fontSize: 15 }} className="medium-black">
          Hometown City
        </small>
      </div>

      <div className="w-full">
        <GooglePlacesInput
          value={hometownLocation}
          setValue={val => setHomeTownLocation(val)}
          setData={val => setHomeTownLocationData(val)}
        />
      </div>

      <div className="w-full mt-3">
        <InputLabel label="Time Zone" color="black" />

        <TimezonePicker
          className="w-full"
          absolute={false}
          value={timeZone}
          placeholder="Select timezone..."
          onChange={timeZone => handleOnChange('timeZone', timeZone)}
        />
      </div>

      <div className="w-full mt-2">
        <InputLabel label="About" color="black" />
        <textarea
          rows={3}
          className="text-input focus:border-primary-color"
          value={about}
          onChange={e => handleOnChange('about', e.target.value)}
        />
      </div>

      <OnboardingAction
        hideSignIn
        navigateLogin={() => {
          history.push('/athlete/login');
        }}
      />
    </Fragment>
  );
}

export default TrainerInfo;
