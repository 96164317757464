import React, { Component } from 'react';
import { TrayvoBlue, Tiktok } from '../../../assets/img';
import { connect } from 'react-redux';
import { BookingsActions } from '../../../store/actions';
import { Loader } from '../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import momentTimezone from 'moment-timezone';
import { TrainingBadge } from '../../Shared';
import {
  priceFormatter,
  formatDate,
  formatTime,
  borderColor,
  getSocialUrl,
  findSportName,
  getBookingStatus,
  getPaymentStatus,
} from '../../../utils/utilities';
import { releasePayment } from '../../../stringConstants';
import { bookingStatus, paymentStatus, statusColor } from '../../../constants';

let connectProps = {
  ...BookingsActions,
};

let connectState = state => ({
  booking: state.Bookings.booking.get('booking'),
  loader: state.User.meta.get('showHUD'),
  sports: state.User.current.get('sports'),
  currentUser: state.User.current.get('currentUser'),
});

const TextContainer = ({ title, value, uppercase }) => (
  <div
    className="flex justify-between my-1 p-3 rounded-md w-full"
    style={{ backgroundColor: '#F5F6FA' }}>
    <span className="primary-text-regular">{title}</span>

    <span className={`primary-text-regular text-right ${uppercase ? 'capitalize' : 'normal-case'}`}>
      {value}
    </span>
  </div>
);

const SmallText = ({ text, black, icon, id, small }) => (
  <div>
    <FontAwesomeIcon icon={icon} size="sm" color="gray" className="mr-2" />

    {id === 'email' ? (
      <button
        className="primary-text-regualar underline normal-case text-blue-700"
        onClick={() => {
          window.open(`mailto:${text}`);
        }}
        title={text}>
        {text}
      </button>
    ) : (
      <span
        className={`font-sf-regular text-xs lowercase mt-2 pr-1 ${
          black ? 'text-black' : 'text-gray-700'
        } ${small ? 'lowercase' : 'capitalize'}`}
        style={{ fontSize: 11 }}>
        {text}
      </span>
    )}
  </div>
);

const ClientDetail = ({ label, value }) => (
  <div className="flex flex-col border-b mt-3 border-gray-400 w-full items-start justify-end content-end">
    <small className={`font-sf-semibold text-xs capitalize pr-1 text-gray-600`}>{label}</small>

    <small className="font-sf-regular text-xs capitalize pr-1 text-primary-color">
      {value ? value : '-'}
    </small>
  </div>
);

const RoundImage = ({ source, trainingType, blueBorder }) => {
  return (
    <div
      className={`rounded-full border-2  ${
        blueBorder ? 'border-blue-700' : borderColor(trainingType)
      }`}>
      <img
        src={source !== null ? source : TrayvoBlue}
        className="h-16 w-16 border rounded-full object-cover"
      />
    </div>
  );
};

const Caption = ({ caption }) => (
  <small className="font-sf-regular text-black text-left text-sm capitalize">{caption}</small>
);

const ReleasePayment = ({ status, paymentStatus, transferCharge, payment, currentUser }) => (
  <div
    className={`border-2 p-2 rounded-lg bg-white mt-2 border-red-600 flex items-center shadow-md animate-pulse ${
      !status || !paymentStatus ? 'opacity-50 cursor-not-allowed' : 'pulse'
    }`}>
    <small className="primary-text-regular normal-case">{releasePayment}</small>

    <button
      disabled={!status || !paymentStatus}
      onClick={() => transferCharge(payment.id, currentUser.attributes.account_id)}
      class="primary-dark-button ml-2 bg-red-600">
      Confirm
    </button>
  </div>
);

const PaymentConfirmedBanner = () => (
  <div className="border-2 p-2 rounded-lg bg-white mt-2 border-green-600 flex items-center shadow-md">
    <small className="primary-text-regular normal-case">
      Thank you for confirming your training was completed, your payment has been sent to your bank
      account and should arrive within 2 business days
    </small>
  </div>
);

let enhancer = connect(connectState, connectProps);

class BookingShow extends Component {
  componentDidMount() {
    this.getBooking();
  }

  getBooking() {
    let {
      getSingleBooking,
      match: { params },
    } = this.props;
    getSingleBooking(params.id);
  }

  transferCharge = async (paymendId, accountId) => {
    await this.props.transferCharge(paymendId, accountId);
    this.getBooking();
  };

  render() {
    let { booking, updateStatus, loader, sports, currentUser } = this.props;
    let service = booking && booking.attributes.provided_service.data;
    let payment = booking && booking.attributes.payment.data;
    let client = booking && booking.attributes.client.data.attributes;
    let statusBooking = booking && booking.attributes.status;
    let statusPayment = payment && payment.attributes.status;

    return (
      <div className="bg-main-background">
        {!loader ? (
          <div className="w-full self-center flex flex-col items-center justify-center">
            {statusBooking !== bookingStatus.CANCELED &&
            statusBooking !== bookingStatus.REJECTED &&
            statusPayment !== paymentStatus.ACCEPTED &&
            currentUser.attributes &&
            currentUser.attributes.account_id ? (
              <ReleasePayment
                status={statusBooking === bookingStatus.COMPLETED}
                paymentStatus={statusPayment === paymentStatus.PAID}
                payment={payment}
                currentUser={currentUser}
                transferCharge={(paymentId, accountId) => {
                  this.transferCharge(paymentId, accountId);
                }}
              />
            ) : (
              statusBooking === bookingStatus.COMPLETED &&
              statusPayment === paymentStatus.ACCEPTED && <PaymentConfirmedBanner />
            )}

            {booking && service && client && (
              <div
                className="flex flex-col w-full md:w-3/5 md:flex-row p-4 md:p-0 justify-between bg-white shadow-md mt-3"
                style={{ borderRadius: 20 }}>
                <div className="mb-5 px-0 md:px-5 w-full md:w-1/2">
                  <div className="flex flex-row">
                    {booking && (
                      <div className="px-2 flex flex-col w-full">
                        <div className="flex flex-col items-center">
                          <h4 className="font-sf-medium text-primary-color">Training Info</h4>

                          <RoundImage
                            source={service.attributes.image_url}
                            trainingType={service.attributes.training_type}
                          />

                          <div className="flex flex-col w-full items-center mt-3">
                            <Caption caption={service.attributes.name} />

                            <div className="text-center max-location-height overflow-hidden">
                              <small className="primary-text-regular uppercase">
                                {parseInt(service.attributes && service.attributes.duration)} mins
                              </small>

                              {service.attributes.training_type !== 'virtual' &&
                                service.attributes.provided_service_locations.data &&
                                service.attributes.provided_service_locations.data.length > 0 && (
                                  <small className="primary-text-regular text-center uppercase">
                                    <small className="text-primary-color px-1">@</small>
                                    {booking.attributes.location}
                                    <br />
                                    {booking.attributes.address}
                                  </small>
                                )}
                            </div>

                            <small className="medium-black" style={{ fontSize: 16 }}>
                              {priceFormatter(service.attributes.price)}
                            </small>

                            <TrainingBadge trainingType={service.attributes.training_type} />
                          </div>
                        </div>

                        {service.attributes.training_type === 'virtual' && (
                          <span className="text-primary-color font-sf-regular text-center tex-xs">
                            Connection details: {service.attributes.connection_detail}
                          </span>
                        )}

                        <span
                          style={{ fontSize: 12 }}
                          className="font-sf-regular text-gray-600 break-words text-xs mt-2 text-center">
                          {service.attributes.discription}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="py-3">
                    <div className="flex flex-col w-full my-2 p-2">
                      <TextContainer
                        title={formatDate(booking.attributes.booking_date)}
                        value={`${formatTime(booking.attributes.from_time)} - ${formatTime(
                          booking.attributes.to_time
                        )} (${momentTimezone.tz(currentUser.attributes.time_zone).format('z')})`}
                      />
                      <TextContainer
                        title="Price"
                        value={priceFormatter(
                          service && service.attributes && service.attributes.price
                            ? service.attributes.price
                            : 0
                        )}
                      />

                      <TextContainer title="Order" value={booking && booking.id} />

                      <TextContainer
                        title="Booking Status"
                        uppercase
                        value={getBookingStatus(statusBooking, statusPayment)}
                      />

                      <TextContainer
                        title="Payment Status"
                        uppercase
                        value={getPaymentStatus(statusBooking, statusPayment)}
                      />

                      <TextContainer title="Note" value={booking && booking.attributes.notes} />

                      {statusBooking === bookingStatus.INCOMPLETE && (
                        <div class="flex flex-row items-end justify-end mt-3">
                          <button
                            onClick={() => updateStatus(booking.id, bookingStatus.REJECTED)}
                            class="primary-dark-button bg-red-700">
                            Reject Booking
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  style={{ width: 1, backgroundColor: '#D7DAE2' }}
                  className="my-10 hidden md:flex h-auto"
                />

                <div className="mb-5 px-2 h-auto w-full md:w-1/2">
                  {client && (
                    <div className="w-full flex items-center flex-col">
                      <h4 className="font-sf-medium text-primary-color">Player Info</h4>

                      <RoundImage source={client.profile_image} blueBorder />

                      <div className="flex flex-col w-full items-center mt-3">
                        <Caption caption={client.first_name + ' ' + client.last_name} />
                        <SmallText text={client.email} icon={faEnvelope} id="email" small />
                        <SmallText text={client.contact_number} icon={faPhone} />

                        <SmallText
                          text={client.city + ', ' + client.state}
                          icon={faLocationArrow}
                        />

                        <div className="flex items-center content-center pt-2">
                          <a
                            href={`${getSocialUrl('facebook')}${client.facebook}`}
                            className="fa fa-facebook"
                            target="_blank"
                          />

                          <a
                            href={`${getSocialUrl('twitter')}${client.twitter}`}
                            class="fa fa-twitter"
                            target="_blank"
                          />

                          <a
                            href={`${getSocialUrl('instagram')}${client.instagram}`}
                            class="fa fa-instagram"
                            target="_blank"
                          />

                          <a
                            href={`${getSocialUrl('tiktok')}${client.tiktok}`}
                            class="fa fa-tiktok"
                            target="_blank">
                            <img src={Tiktok} className="w-4 h-4" />
                          </a>

                          <a href={client.website} class="fa fa-globe" target="_blank" />
                        </div>
                        <div className="w-full md:w-3/4">
                          <ClientDetail label="High School" value={client.high_school} />

                          <ClientDetail label="Club" value={client.club} />

                          <ClientDetail
                            label="College"
                            value={client.college.data && client.college.data.attributes.name}
                          />
                          <ClientDetail
                            label="Sport"
                            value={findSportName(client.sport_id, sports).label}
                          />

                          <ClientDetail
                            label="Position"
                            value={client.position.map(
                              (position, index, arr) =>
                                `${[position]}${index !== arr.length - 1 ? ', ' : ''} `
                            )}
                          />

                          <ClientDetail label="Class" value={client.passing_year} />

                          <ClientDetail label="About" value={client.about} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div
              className="flex flex-col w-full md:w-3/5 p-3 items-center bg-white shadow-md mt-2"
              style={{ borderRadius: 20 }}>
              <h5 className="text-black font-sf-medium">Location Info</h5>

              <div className="w-full">
                <div id="mapBox" className="m-3">
                  <Map
                    google={this.props.google}
                    zoom={2}
                    style={{
                      width: '100%',
                      height: '250px',
                      borderRadius: 10,
                      position: 'relative',
                    }}
                    initialCenter={{ lat: 47.444, lng: -122.176 }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex  container min-h-screen justify-center items-center">
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
})(enhancer(BookingShow));
