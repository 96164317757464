import React from 'react';
import { Loader } from '../Loader';
import { TrayvoBlue, TrayvoBookingLogo } from '../../../assets/img';
import SettingIcon from '../SettingIcon/SettingIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import {
  priceFormatter,
  notify,
  borderColor,
  isTrainingLocationPresent,
} from '../../../utils/utilities';
import { ToggleButton, TrainingBadge, ClipboardCopyButton, AvailabilitySet } from '../../Shared';
import TrainingOverlay from '../../Shared/TrainingOverlay';

const DashboardTrainings = ({
  providedServices,
  deleteTraining,
  handleOnToggle,
  setEditTrainingPopup,
  setNewTrainingPopup,
  ...props
}) => {
  return (
    <div className="mx-0 md:grid md:grid-cols-3 md:gap-2 mt-1 update-training-step">
      {providedServices.length > 0 ? (
        providedServices
          .slice(0, 3)
          .filter(item => !item.attributes.deleted)
          .map(({ attributes }, index) => {
            if (!attributes.title) {
              return (
                <div
                  key={index}
                  className={`hover:bg-gray-400 overflow-hidden relative trainings-margin shadow-md p-2 bg-white mx-1 cursor-pointer border-t-4 ${borderColor(
                    attributes.training_type
                  )}`}
                  onClick={e => {
                    setEditTrainingPopup(attributes.id);
                  }}
                  style={{ height: '270px', borderRadius: 10 }}>
                  {isTrainingLocationPresent(attributes) && (
                    <TrainingOverlay
                      trainingId={attributes.id}
                      trainingName={attributes.name}
                      toggleEditTrainingPopup={setEditTrainingPopup}
                    />
                  )}

                  <div className="flex flex-col">
                    <div className="flex justify-between w-full">
                      <div onClick={e => e.stopPropagation()}>
                        <ToggleButton
                          status={attributes.status}
                          key={attributes.id}
                          id={attributes.id}
                          handleOnToggle={handleOnToggle}
                          training={attributes}
                        />
                      </div>

                      <div
                        className={`self-center rounded-full items-center ml-3 ${
                          attributes.status === 'unpublished' && 'opacity-50'
                        }`}>
                        <img
                          src={attributes.image_url ? attributes.image_url : TrayvoBookingLogo}
                          className="object-cover h-16 w-16 rounded-full overflow-hidden shadow-md"
                        />
                      </div>

                      <div className="flex delete-training-step">
                        <SettingIcon>
                          <div className="flex flex-col" onClick={e => e.stopPropagation()}>
                            <div className="text-center">
                              <button
                                className="text-black hover:text-red-700 text-xs"
                                onClick={e => {
                                  e.stopPropagation();
                                  attributes.deleteable
                                    ? deleteTraining(attributes.id)
                                    : notify('There is a booking associated');
                                }}>
                                Delete
                              </button>
                            </div>

                            <div className="text-center">
                              <button
                                className="text-black hover:text-blue-500 text-xs"
                                onClick={e => {
                                  e.stopPropagation();
                                  setEditTrainingPopup(attributes.id);
                                }}>
                                Edit
                              </button>
                            </div>
                          </div>
                        </SettingIcon>
                      </div>
                    </div>

                    <div
                      className={`flex flex-col mx-1 items-center pt-3 ${
                        attributes.status === 'unpublished' && 'opacity-50'
                      }`}>
                      <div className="flex">
                        {attributes &&
                          attributes.privacy_setting &&
                          attributes.privacy_setting == 'private' && (
                            <FontAwesomeIcon
                              icon={faLock}
                              size="lg"
                              color="gray"
                              className="mr-1 p-1"
                            />
                          )}

                        <small
                          style={{
                            fontSize:
                              attributes && attributes.name && attributes.name.length > 30
                                ? 10
                                : 13,
                          }}
                          className="font-sf-medium text-black capitalize overflow-hidden text-xs">
                          {attributes && attributes.name && attributes.name.length > 30
                            ? `${attributes.name.substring(0, 30)}...`
                            : attributes && attributes.name && attributes.name}
                        </small>
                      </div>

                      <div className="text-center max-location-height overflow-hidden pt-1">
                        <small className="font-sf-regular text-gray-900 font-normal text-xs">
                          {parseInt(attributes && attributes.duration)} mins
                        </small>

                        {attributes.training_type !== 'virtual' &&
                          attributes.provided_service_locations.data &&
                          attributes.provided_service_locations.data.length > 0 && (
                            <small className="font-sf-regular text-gray-900 font-normal text-xxs text-center capitalize">
                              <small className="text-black px-1">@</small>
                              {attributes.provided_service_locations.data
                                .slice(0, 2)
                                .map((loc, index) => {
                                  return (
                                    loc.attributes.location +
                                    `${
                                      index + 1 !==
                                      attributes.provided_service_locations.data.slice(0, 2).length
                                        ? ' • '
                                        : ''
                                    }`
                                  );
                                })}
                              {attributes.provided_service_locations.data.length > 2 && ' ...'}
                            </small>
                          )}
                      </div>

                      <small className="font-sf-medium text-gray-900 capitalize text-sm font-normal self-center py-1">
                        {priceFormatter(attributes.price)}
                      </small>

                      <TrainingBadge trainingType={attributes.training_type} />

                      <div style={{ paddingTop: '5px' }}>
                        <AvailabilitySet
                          {...props}
                          availabilitySet={attributes.availability_set}
                          training={attributes}
                          status={attributes.status === 'published'}
                        />
                      </div>

                      <div className="absolute bottom-0 left-0 p-2 w-full">
                        {!isTrainingLocationPresent(attributes) && (
                          <ClipboardCopyButton
                            status={attributes.status}
                            handleOnToggle={(id, status) => handleOnToggle(id, status)}
                            slug={attributes.slug}
                            id={attributes.id}
                            visible={false}
                            training={attributes}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  className="hover:bg-gray-400 flex items-center justify-center p-2 bg-light-gray mx-2 my-5 w-48 rounded-md overflow-hidden cursor-pointer"
                  onClick={() => {
                    setNewTrainingPopup(true);
                  }}>
                  <div className="flex flex-col mx-1 text-black">
                    <span style={{ fontSize: 40 }} className="text-center">
                      +
                    </span>

                    <span style={{ fontSize: 20 }} className="text-center">
                      New Training
                    </span>
                  </div>
                </div>
              );
            }
          })
      ) : (
        <div>
          <div fluid className="flex p-4 items-center justify-center">
            {props.loader ? (
              <Loader />
            ) : (
              <h3 className={'font-sf-semibold text-center text-sm text-black'}>
                No Trainings Available
              </h3>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardTrainings;
