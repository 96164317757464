import React from 'react';

const TrainingOverlay = ({ trainingId, trainingName, toggleEditTrainingPopup }) => {
  return (
    <div
      className="overlay overlay-light-gray text-center absolute top-0 left-0 right-0 bottom-0 text-black z-2 flex flex-column justify-end px-2 cursor-default "
      onClick={e => e.stopPropagation()}>
      <button
        className="rounded-md mb-2 bg-white items-center hover:bg-primary-color text-primary-color hover:text-gray-400 px-2 py-1 text-center w-full focus:outline-none"
        style={{ borderColor: '#D7DAE2', borderWidth: 1 }}
        onClick={e => {
          e.stopPropagation();
          toggleEditTrainingPopup(trainingId);
        }}>
        <small className='font-sf-regular text-xs'>
          Set this training option up
        </small>
      </button>
    </div>
  );
};

export default TrainingOverlay;
