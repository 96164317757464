import React, { useState } from 'react';
import { ModalBackground, Loader } from '../Shared';
import { connect } from 'react-redux';
import { notify } from '../../utils/utilities';
import { UserActions } from '../../store/actions';

let connectProps = {
  ...UserActions,
};

let enhancer = connect(null, connectProps);

const ChangePassword = props => {
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);
  const [loader, setLoader] = useState(null);

  const submit = async e => {
    e.preventDefault();

    if (password !== confirmPassword) {
      notify('Password does not match!');
      return;
    }

    let payload = {
      athlete: {
        current_password: oldPassword,
        password: password,
        password_confirmation: confirmPassword,
      },
    };

    setLoader(true);
    props.updateWithCurrentPassword(payload).then(result => {
      if (result?.data) notify('Password Updated Successfully!', 'success');
      setLoader(null);
      setPassword(null);
      setConfirmPassword(null);
      props.closePopup();
    });
  };

  return (
    <div
      style={{ zIndex: 2 }}
      class={
        props.visible
          ? `fixed inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center`
          : 'hidden'
      }>
      <div onClick={props.closePopup}>
        <ModalBackground />
      </div>

      <div
        class="bg-white rounded-lg shadow-xl transform transition-all max-w-lg w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline">
        <div className="overflow-scroll max-h-screen py-8">
          <form className="w-full" onSubmit={e => submit(e)}>
            <div class="px-4">
              <div>
                <div class="text-center">
                  <h3 class="medium-black text-lg" id="modal-headline">
                    Change Password
                  </h3>
                </div>
              </div>
            </div>

            <div class="bg-gray-50 px-4 sm:px-6 flex flex-col">
              <div class="mt-2">
                <input
                  class="text-input"
                  id="password"
                  type="password"
                  value={props.oldPass}
                  placeholder="Current Password"
                  onChange={e => setOldPassword(e.target.value)}
                  required
                />
              </div>

              <div class="mt-2">
                <input
                  class="text-input"
                  id="password"
                  type="password"
                  value={props.password}
                  placeholder="Password"
                  onChange={e => setPassword(e.target.value)}
                  required
                />
              </div>

              <div class="mt-2">
                <input
                  class="text-input"
                  id="password"
                  type="password"
                  value={props.confirmPass}
                  placeholder="Password Confirmation"
                  onChange={e => setConfirmPassword(e.target.value)}
                  required
                />
              </div>

              <span class="flex w-full rounded-md shadow-sm mt-3">
                <button type="submit" disabled={loader} class="primary-dark-button w-full">
                  Update Password
                </button>
              </span>
            </div>

            {loader && (
              <div className="flex justify-center items-center">
                <Loader small />
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default enhancer(ChangePassword);
