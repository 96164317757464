import api from '../api';
import { client } from '../index';
import { changeButtonLoading } from '../User/actions';
import qs from 'qs';

export const showHUD = () => {
  return { type: 'SHOW_HUD' };
};

export const hideHUD = () => {
  return { type: 'HIDE_HUD' };
};

export const getProvidedServices = (page = 1, per_page = 10) => async dispatch => {
  let payload = {
    page,
    per_page,
  };
  let paramsString = qs.stringify(payload);
  const { data } = await client().get(`${api.provided()}?${paramsString}`);

  dispatch({ type: 'SET_PROVIDED_SERVICES', payload: { data: data.data, more: page > 1 } });
  dispatch({ type: 'SET_PROVIDED_SERVICES_PAGY', payload: { data: data.pagy } });
  return data;
};

export const getSingleService = id => async dispatch => {
  const { data } = await client().get(`${api.provided()}/${id}`);
  dispatch({ type: 'SET_SINGLE_SERVICE', payload: { data: data.data } });
  dispatch(hideHUD());
  return data;
};

export const clearSingleService = () => async dispatch => {
  dispatch({ type: 'SET_SINGLE_SERVICE', payload: { data: null } });
};

export const updateProvidedService = (id, payload) => async dispatch => {
  let { data } = await client().put(`${api.provided()}/${id}`, payload);

  if (data && data.data && data.data.attributes) {
    dispatch({ type: 'UPDATE_PROVIDED_SERVICE', payload: { data: data.data, id } });
  }
};

export const getTrainingSignedUrl = () => async dispatch => {
  const { data } = await client().get(api.trainingSignedUrl());
  return data;
};

export const createService = payload => async dispatch => {
  let { data } = await client().post(`${api.provided()}`, payload);
  dispatch(changeButtonLoading(false));
  return data;
};

export const deleteProvidedService = id => async dispatch => {
  let { data } = await client().put(`${api.provided()}/${id}`, {
    provided_service: {
      deleted: true,
    },
  });

  if (data && data.data && data.data.attributes) {
    dispatch({ type: 'UPDATE_PROVIDED_SERVICE', payload: { data: data.data, id } });
  }
};
