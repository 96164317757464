import React from 'react';
import { notify, ifTrainingHasLocation, getConsumerUrl } from '../../utils/utilities';
import LoaderMini from 'react-loader-spinner';

function ClipboardCopyButton({ status, handleOnToggle, slug, id, training, visible }) {
  const checkStatusValidity = (id, status) => {
    ifTrainingHasLocation(training.training_type, training.provided_service_locations)
      ? handleOnToggle(id, status)
      : notify('Please add a training location first.');
  };

  return (
    <div className="flex w-full">
      <button
        className="rounded-md bg-white items-center hover:bg-primary-color text-primary-color hover:text-gray-400 px-2 py-1 text-center w-full focus:outline-none"
        style={{ borderColor: '#D7DAE2', borderWidth: 1 }}
        onClick={e => {
          e.stopPropagation();
          if (status === 'published') {
            let message = `${getConsumerUrl()}/${slug.replace(/-/g, '/')}`;

            if (typeof navigator.clipboard == 'undefined') {
              console.log('navigator.clipboard');
              var textArea = document.createElement('textarea');
              textArea.value = message;
              textArea.style.position = 'fixed'; //avoid scrolling to bottom
              document.body.appendChild(textArea);
              textArea.focus();
              textArea.select();

              try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'Link has been copied' : 'unsuccessful';
                notify(msg, successful && 'success');
              } catch (err) {
                notify('Was not possible to copy te text: ', err);
              }

              document.body.removeChild(textArea);
              return;
            }
            navigator.clipboard.writeText(message).then(
              function () {
                notify(`Training option link has been copied.`, 'success');
              },
              function (err) {
                notify('Unsuccessful!');
              }
            );
          } else checkStatusValidity(id, status);
        }}>
        <small className="font-sf-regular text-xs">
          {status === 'published' ? 'Copy link' : 'Turn on'}
        </small>
      </button>

      <LoaderMini visible={visible} type="ThreeDots" id={id} color="#000" height={25} width={35} />
    </div>
  );
}
export default ClipboardCopyButton;
