import React from 'react';

function InputLabel({ label, color, bold }) {
  return (
    <small
      className={bold ? 'medium-black' : 'font-sf-regular'}
      style={{ color: color ? color : '#6E7994', fontSize: '12px' }}>
      {label}
    </small>
  );
}
export default InputLabel;
