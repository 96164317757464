import React from 'react';
import { ServiceProfessionalSignin } from '../Athlete/SignIn';

function App() {
  return (
    <div>
      <ServiceProfessionalSignin />
    </div>
  );
}

export default App;
