import React from 'react';

function Banner({ createAvailability, createTraining, bookingLink }) {
  return (
    <div className="flex items-center content-center self-center">
      <div
        style={{ paddingTop: '14px', paddingBottom: '14px' }}
        className="flex shadow-md md:rounded-md bg-white md:px-16 font-sf-regular w-full justify-center items-center">
        <small className="text-black text-xxs md:text-xs font-sf-regular mr-2">
          How trayvo works:
        </small>

        <div className="flex md:text-xs text-xxs mx-1 text-black">
          <span>1.</span>
          <small className="text-xxs md:text-xs focus:outline-none">Create Training Option</small>
        </div>

        <div className="flex md:text-xs text-xxs mx-2 text-black">
          <span>2.</span>
          <small className="text-xxs md:text-xs focus:outline-none">Create Availability</small>
        </div>

        <div className="flex md:text-xs text-xxs mx-1 text-black">
          <span>3.</span>
          <small className="font-sf-regular text-xxs md:text-xs">Share Booking Link</small>
        </div>
      </div>
    </div>
  );
}
export default Banner;
