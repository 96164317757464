import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { BookingsActions } from '../../../store/actions';
import { Loader } from '../Loader';
import moment from 'moment';
import { formatDate, priceFormatter } from '../../../utils/utilities';

const verticalBorder = { borderLeftWidth: 1, borderColor: '#CCCCCC' };

let connectProps = {
  ...BookingsActions,
};

let connectState = state => ({
  rejectedBookings: state.Bookings.booking.get('rejectedBookings'),
  bookingRequestPagy: state.Bookings.booking.get('bookingRequestPagy'),
  loader: state.User.meta.get('showHUD'),
});

let enhancer = connect(connectState, connectProps);

const renderHeadings = () => {
  const heads = [
    'Training Name',
    'Customer Name',
    'Customer Contact',
    'Date',
    'Total',
    'Details',
    'Status',
  ];
  return heads.map(item => {
    return (
      <th className="font-sf-medium bg-primary-color text-white font-thin sticky-header text-center text-xs px-4 py-3">
        {item}
      </th>
    );
  });
};

class RejectedBookings extends Component {
  componentDidMount() {
    let { getRejectedBookings } = this.props;
    getRejectedBookings();
  }

  moveToDetail(id) {
    let { history } = this.props;

    history.push({
      pathname: `/bookings/${id}`,
    });
  }

  handlePageClick = data => {
    let { getRejectedBookings, history } = this.props;
    history.push(`/booking_requests?page=${data.selected + 1}`);
    getRejectedBookings(data.selected + 1);
  };

  acceptRequest(booking) {
    let requestId = booking.attributes.id;

    let entry = booking.attributes.booking_request_entry.data;
    let service = {
      provided_service_id: entry.attributes.provided_service.data.id,
      booking_date: moment(entry.attributes.booking_date).format('YYYY-MM-DD'),
    };

    let payload = {
      booking: {
        client_id: booking.attributes.client.data.id,
        payment_id: booking.attributes.payment.data.id,
        booked_service_attributes: service,
      },
    };
    this.props.acceptRequest(payload, requestId);
  }

  render() {
    let { loader, rejectedBookings, bookingRequestPagy, searchText } = this.props;

    let rejectedRequests =
      searchText !== ''
        ? rejectedBookings.filter(
            booking =>
              booking.attributes.client.data.attributes.contact_number
                .toLowerCase()
                .includes(searchText.toLowerCase()) && booking.attributes.status === 'rejected'
          )
        : rejectedBookings.filter(booking => booking.attributes.status === 'rejected');

    let filteredBookings = rejectedRequests;

    return (
      <div className="bg-main-background min-h-screen" fluid>
        <div className="mt-3">
          <div className="flex flex-col">
            <table
              class="w-full bg-white gfg overflow-hidden tableizer-firstrow"
              style={{ borderRadius: 20 }}>
              <thead>
                <tr className="bg-primary-color border-b border-gray-300">{renderHeadings()}</tr>
              </thead>

              <tbody>
                {filteredBookings.length > 0 ? (
                  filteredBookings.map(({ attributes }) => (
                    <tr
                      className="cursor-pointer border-b border-main-background rt-tr-group"
                      onClick={() => this.moveToDetail(attributes.id)}>
                      <td class="font-sf-regular text-center pl-2 text-gray-700 text-xs border-training-individual rounded-md border-l-2 capitalize">
                        {attributes.provided_service.data.attributes.name}
                      </td>

                      <td
                        class="font-sf-regular text-center pl-2 text-primary-color text-xs py-1 capitalize"
                        style={verticalBorder}>
                        {attributes.client.data.attributes.first_name}{' '}
                        {attributes.client.data.attributes.last_name}
                      </td>

                      <td
                        class="font-sf-regular text-center pl-2 text-primary-color text-xs py-1 mini-hide"
                        style={verticalBorder}>
                        {attributes.client.data.attributes.contact_number}
                      </td>

                      <td
                        class="font-sf-regular text-center pl-2 text-primary-color text-xs py-1 mini-hide"
                        style={verticalBorder}>
                        {formatDate(attributes.booking_date)}
                      </td>

                      <td
                        class="font-sf-regular text-center pl-2 text-primary-color text-xs py-1 mini-hide"
                        style={verticalBorder}>
                        {priceFormatter(attributes.provided_service.data.attributes.price)}
                      </td>

                      <td class="text-center" style={verticalBorder}>
                        <small className="text-xs font-sf-medium items-center p-1 px-2 text-blue-600 hover:bg-blue-600 hover:text-white rounded-md focus:outline-none">
                          View
                        </small>
                      </td>

                      <td
                        class="font-sf-regular text-center pl-2 text-primary-color text-xs py-1 mini-hide flex items-center justify-center"
                        style={verticalBorder}>
                        <small
                          className="px-2 font-sf-regular text-center capitalize py-1 rounded-lg bg-red-600 text-white"
                          style={{ font: 8 }}>
                          {attributes.status}
                        </small>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <div fluid className="p-4">
                        {loader ? (
                          <Loader />
                        ) : (
                          <h3 className={'text-center font-sf-semibold text-sm text-black'}>
                            No Rejected Bookings.
                          </h3>
                        )}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {bookingRequestPagy && (
            <div className="mt-4">
              <ReactPaginate
                previousLabel={'«'}
                nextLabel={'»'}
                breakLabel={<span className="gap">...</span>}
                pageCount={bookingRequestPagy.pages}
                onPageChange={this.handlePageClick.bind(this)}
                containerClassName={'pagination'}
                previousLinkClassName={'previous_page'}
                nextLinkClassName={'next_page'}
                disabledClassName={'disabled'}
                activeClassName={'active'}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default enhancer(RejectedBookings);
