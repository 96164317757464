import React, { Fragment } from 'react';
import { Loader } from '../Loader';
import { SocialLink, OnboardingAction } from '../../Shared';
import { appendSymInStart, validateSocialHandles } from '../../../utils/utilities';

function AddSocials({
  website,
  twitter,
  facebook,
  tiktok,
  instagram,
  handleOnChange,
  loader,
  history,
  setError,
  errorText,
}) {
  return (
    <Fragment>
      <div className="text-left">
        <div className="flex w-full justify-end">
          <small className="self-end text-red-600">
            {errorText ? `Invalid ${errorText} username` : ''}
          </small>
        </div>

        <SocialLink
          id="Facebook"
          value={facebook}
          handleOnChange={(id, value) => {
            let result = validateSocialHandles(value);
            setError(!result ? 'facebook' : '');
            handleOnChange(id, value);
          }}
        />

        <SocialLink
          id="Twitter"
          value={twitter}
          handleOnChange={(id, value) => {
            let result = validateSocialHandles(value);
            setError(!result ? 'twitter' : '');
            handleOnChange(id, value);
          }}
        />

        <SocialLink
          id="Instagram"
          value={instagram}
          handleOnChange={(id, value) => {
            let result = validateSocialHandles(value);
            setError(!result ? 'instagram' : '');
            handleOnChange(id, value);
          }}
        />

        <SocialLink
          id="Tiktok"
          value={tiktok}
          handleOnChange={(id, value) => {
            handleOnChange(id, appendSymInStart(value, '@'));
          }}
        />

        <SocialLink
          id="Website"
          value={website}
          handleOnChange={(id, value) => handleOnChange(id, value)}
        />

        <OnboardingAction
          skip
          hideSignIn
          disableAction={errorText !== ''}
          navigateLogin={() => {
            history.push('/athlete/login');
          }}
        />

        {loader && (
          <div className="flex justify-center self-center items-center mt-3">
            <Loader color={'#000'} small />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default AddSocials;
