import React from 'react';
import {
  formatTime,
  formatDate,
  backgroundColor,
  convertDate,
  borderColor,
} from '../../../utils/utilities';

const DayCalendar = ({ attributes, moveToDetail, getTrainingType }) => {
  return (
    <div className="flex w-full items-center justify-center overflow-hidden">
      {attributes && (
        <div className="w-1/4 flex h-full py-1 items-center justify-between px-2">
          <small className="text-black text-xs font-sf-regular">Available</small>

          <small className="text-black text-xs font-sf-regular">|</small>

          <small className="text-black text-xs font-sf-regular">
            {convertDate(formatDate(attributes.booking_date))}
          </small>

          <small className="text-black text-xs font-sf-regular">|</small>

          <small className="text-black text-xs font-sf-regular">
            {formatTime(attributes.from_time)} - {formatTime(attributes.to_time)}
          </small>

          <small className="text-black text-xs font-sf-regular">|</small>

          <small className="text-black text-xs font-sf-regular">
            {getTrainingType(attributes.entry_color)}
          </small>
        </div>
      )}

      <div className="w-3/4 flex px-2 justify-end whitespace-normal overflow-hidden flex-wrap">
        {attributes &&
          attributes.bookings &&
          attributes.bookings.data.map(({ attributes }) => {
            let training = attributes.provided_service.data.attributes;
            return (
              <div
                className={`flex items-center px-1 mr-1 h-full w-64 justify-between rounded ${borderColor(
                  training.training_type
                )}`}
                style={{ borderWidth: '0.5px' }}
                onClick={() => moveToDetail(attributes.id)}>
                <div
                  className={`h-1 w-1 rounded-full ${backgroundColor(training.training_type)}`}
                />

                <small style={{ fontSize: 9 }} className="text-black text-xs font-sf-regular">
                  Booking
                </small>

                <small className="text-black text-xs font-sf-regular">|</small>

                <small className="text-black text-xs font-sf-regular" style={{ fontSize: 9 }}>
                  {attributes.client.data.attributes.first_name}{' '}
                  {attributes.client.data.attributes.last_name}
                </small>

                <small className="text-black text-xs font-sf-regular">|</small>

                <small className="text-black text-xs font-sf-regular" style={{ fontSize: 9 }}>
                  {convertDate(formatDate(attributes.booking_date))}
                </small>

                <small className="text-black text-xs font-sf-regular">|</small>

                <small className="text-black text-xs font-sf-regular" style={{ fontSize: 9 }}>
                  {formatTime(attributes.from_time)} - {formatTime(attributes.to_time)}
                </small>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DayCalendar;
