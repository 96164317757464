import React, { Component } from 'react';
import { formatTime } from '../utils/utilities';
import moment from 'moment';

function CalendarEntries(WrappedComponent, selectData) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        dateSelectionModal: false,
        editAvailability: false,
        currentMonth: '',
        availId: null,
        updateFrom: null,
        updateTo: null,
        updateRangeFrom: null,
        updateRangeTo: null,
        repCount: null,
        optionType: 0,
        days: [],
        selectionText: 'Events can be scheduled over 60 calendar days',
        refreshModal: true,
        training_type: null,
        indicateOverlap: null,
        training_color: null,
        selected_trainings: [],
      };
      this.closeDateSelection = this.closeDateSelection.bind(this);
    }

    closeDateSelection() {
      this.props.changeButtonLoading(false);
      this.setState({
        dateSelectionModal: false,
        editAvailability: false,
        refreshModal: !this.state.refreshModal,
      });
    }

    openDateSelection = () => {
      this.setState({
        availId: null,
        updateFrom: null,
        updateTo: null,
        updateRangeFrom: null,
        updateRangeTo: null,
        repCount: null,
        optionType: 0,
        dateSelectionModal: true,
        refreshModal: !this.state.refreshModal,
      });
    };

    openUpdateDateSelection = (id, availability) => {
      this.setState(
        {
          availId: id,
          optionType: availability.repetition_count
            ? 0
            : availability.repetition_never_stop
              ? 2
              : 1,
          updateFrom: moment(availability.from_time)
            .utc()
            .format('hh:mm a')
            .toUpperCase()
            .replace(/\s/g, ''),
          updateTo: moment(availability.to_time)
            .utc()
            .format('hh:mm a')
            .toUpperCase()
            .replace(/\s/g, ''),
          updateRangeFrom: availability.repetition_from,
          updateRangeTo: availability.repetition_until,
          repCount: availability.repetition_count,
          days: availability.days,
          training_type: availability.training_type,
          training_color: availability.training_color,
          selected_trainings: availability.selected_trainings,
        },
        () => {
          this.setState({ editAvailability: true });
        }
      );
    };

    resetOverlapIndication = () => {
      this.setState({ indicateOverlap: null });
    };

    checkAndSetOverlapping = data => {
      let error = 'Your new availability is the same Date/Time as an existing one ';

      if (data && data.errors) {
        this.props.changeButtonLoading(false);

        let overlapped = data.errors.data[0];
        const isSameDate = overlapped[0].date === overlapped[overlapped.length - 1].date

        error = {
          date: isSameDate ? overlapped[0].date : `${overlapped[0].date} - ${overlapped[overlapped.length - 1].date}`,
          time: `${formatTime(overlapped[0].from_time)} - ${formatTime(overlapped[0].to_time)}`
        }

        this.setState({ indicateOverlap: error });
      } else this.closeDateSelection();
    };

    applyDateSelection = async (payload, text, availId, force_overlap = false) => {
      this.setState({ selectionText: text });
      this.props.changeButtonLoading(true);

      if (availId) {
        await this.props.updateAvailability(payload, availId);
        this.closeDateSelection();
      } else {
        await this.props.createAvailability(payload).then(data => {
          if (!force_overlap) {
            this.checkAndSetOverlapping(data);
          } else this.closeDateSelection();
        });
      }

      await this.props.getAvailabilities();
      await this.props.getCalendarEntries(this.state.currentMonth);
      await this.props.getSchedule();
      await this.props.getProvidedServices();
      this.props.changeButtonLoading(false);
      if (force_overlap) this.closeDateSelection();
    };

    setCurrentMonth = month => {
      this.setState({ currentMonth: month });
    };

    render() {
      let {
        dateSelectionModal,
        selectionText,
        availId,
        updateFrom,
        updateTo,
        updateRangeFrom,
        updateRangeTo,
        optionType,
        refreshModal,
        repCount,
        editAvailability,
        days,
        training_type,
        indicateOverlap,
        training_color,
        selected_trainings,
      } = this.state;

      return (
        <WrappedComponent
          dateSelectionModal={dateSelectionModal}
          editAvailability={editAvailability}
          refreshModal={refreshModal}
          selectionText={selectionText}
          days={days}
          availId={availId}
          updateFrom={updateFrom}
          updateTo={updateTo}
          updateRangeFrom={updateRangeFrom}
          updateRangeTo={updateRangeTo}
          optionType={optionType}
          repCount={repCount}
          training_type={training_type}
          training_color={training_color}
          selected_trainings={selected_trainings}
          closeDateSelection={this.closeDateSelection}
          openDateSelection={this.openDateSelection}
          openUpdateDateSelection={this.openUpdateDateSelection}
          applyDateSelection={this.applyDateSelection}
          resetOverlapIndication={this.resetOverlapIndication}
          setCurrentMonth={this.setCurrentMonth}
          indicateOverlap={indicateOverlap}
          {...this.props}
        />
      );
    }
  };
}

export default CalendarEntries;
